import { rbacApi } from '@/api/rbac.api';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import {
  getGroupsUserHasAccessTo,
  getSubdivisionsUserHasAccessTo
} from '@/utils/rbac.util';
import { Store } from 'vuex';
import { RbacState } from './rbac.state';

export const rbacActions = {
  async getMyRoles(store: Store<RbacState>) {
    try {
      store.commit('GET_MY_ROLES_LOADING');

      const { data } = await rbacApi.getMyRoles();

      store.commit('GET_MY_ROLES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_MY_ROLES_ERROR', error);
    }
  },
  async getUserPermissionsMatrix(store: Store<RbacState>) {
    try {
      store.commit('GET_USER_PERMISSIONS_MATRIX_LOADING');

      const { data } = await rbacApi.getUserPermissionMatrix();
      store.commit(
        'SET_GROUPS_USER_HAS_ACCESS_TO',
        getGroupsUserHasAccessTo(data.read)
      );
      store.commit(
        'SET_GROUP_TYPES_USER_HAS_ACCESS_TO',
        getSubdivisionsUserHasAccessTo(data.read, EntityTypes.GROUP)
      );

      store.commit('GET_USER_PERMISSIONS_MATRIX_SUCCESS', data);
    } catch (error) {
      store.commit('GET_USER_PERMISSIONS_MATRIX_ERROR', error);
    }
  }
};
