import { subscriptionApi } from '@/api/subscription.api';
import { Store } from 'vuex';
import {
  ExtendProductSubscriptionPayload,
  SubscriptionState,
} from './subscription.state';

export const subscriptionActions = {
  async getMySubscriptions(store: Store<SubscriptionState>) {
    try {
      store.commit('GET_MY_SUBSCRIPTION_LOADING');

      const { data } = await subscriptionApi.getMySubscriptions();
      store.commit('GET_MY_SUBSCRIPTION_SUCCESS', data);
    } catch (error) {
      store.commit('GET_MY_SUBSCRIPTION_ERROR', error);
    }
  },
  async extendProductSubscription(
    store: Store<SubscriptionState>,
    payload: ExtendProductSubscriptionPayload,
  ) {
    try {
      store.commit('EXTEND_MY_SUBSCRIPTION_LOADING');

      const { data } = await subscriptionApi.extendProductSubscription(payload);
      store.commit('EXTEND_MY_SUBSCRIPTION_SUCCESS', data);
    } catch (error) {
      store.commit('EXTEND_MY_SUBSCRIPTION_ERROR', error);
    }
  },
};
