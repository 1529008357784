import { NavigationGuard } from 'vue-router';
import { redirectToLoginSignup } from './store/plugins/forbidden-response-handler.plugin';
import store, { RootState } from './store/store';

export const GeneralRouteGuard: NavigationGuard = async (to, from, next) => {
  await store.dispatch('auth/checkSessionHealth');
  const isNotLoggedIn = (): boolean =>
    !(store.state as RootState).auth.isLoggedIn;
  if (to.meta && to.meta.authRequired && isNotLoggedIn()) {
    await store.dispatch('auth/checkSessionHealth');
    if (isNotLoggedIn()) {
      // If user is not logged in, check the attribute value validity
      // If any missing required attribute value found, redirect to profile.
      await store.dispatch('userAttribute/getAttributeValueValidity');
      if (store.getters['userAttribute/isMissingAttributeValue']) {
        return next({ name: 'UserProfile' });
      }
      redirectToLoginSignup();
      return next(false);
    }

    // Check if any destination app url stored for this user, if yes it means
    // user still have missing required attribute value, so redirect them to profile
    await store.dispatch('profile/getDestinationAppUrl');
    const destinationAppUrl: string =
      store.getters['profile/destinationAppUrl'];
    if (!destinationAppUrl || destinationAppUrl === '') {
      return next();
    } else {
      if (
        store.getters['userAttribute/isMissingAttributeValue'] === undefined
      ) {
        await store.dispatch('userAttribute/getAttributeValueValidity');
        if (store.getters['userAttribute/isMissingAttributeValue']) {
          return next({ name: 'UserProfile' });
        }
      }
    }
  }

  return next();
  // FIXME:
  // for guest user, if they access to non exist page, it will show a flash 404 page and redirect to login,
  // this is because forbidden-response-handle-plugin will always redirect unauthenticated user
  // to login page if unauthenticated this will redirect user to login
};

/**
 * This guard has three possible states to look for:
 * - If the user is an admin (has the role super-admin-role), in which
 *   case the user has unrestricted access
 * - If the user is accessing a page that is not a module like the
 *   home page or the about page
 * - If the user is accessing a page marked as a module as a non-admin,
 *   in which case the system checks if the module is included in the
 *   roles that they are in. The resolve of the guard in this case will
 *   depend on whether the user has access to the entire module, or if
 *   they have access through an instance (access to specific groups,
 *   for example) or if they have partial access to a module (able to
 *   view products but not create, etc.).
 * @param to
 * @param from
 * @param next
 * @constructor
 */
export const CasbinRouteGuard: NavigationGuard = async (to, from, next) => {
  const userPermissionsMatrix = store.getters['rbac/userPermissionsMatrix'];

  /**
   * Avoid calling /user-permissions-matrix API unless page is reloaded or
   * the matrix is not there in the store.
   *
   * !from = page has been reloaded and the user has not navigated to a page
   * **from** somewhere.
   */
  if (!userPermissionsMatrix || !from) {
    await store.dispatch('rbac/getUserPermissionsMatrix');
  }

  const rootModules = store.getters['rbac/rootModules'] ?? [];
  const readModules = store.getters['rbac/readModules'] ?? [];
  const isAdmin = store.getters['rbac/isAdmin'] ?? false;
  const module = to.meta.module;
  const instanceType = to.meta.instanceType;

  if (isAdmin) {
    return next();
  } else if (module === 'non_module') {
    return next();
  } else if (rootModules.length > 0 && rootModules.includes(module)) {
    return next();
  } else if (
    readModules.length > 0 &&
    checkForImplicitAccess(module, readModules)
  ) {
    return next();
  } else if (instanceType) {
    if (moduleHasInstances(instanceType, readModules)) {
      return next();
    }
  } else {
    return next({ name: 'access-forbidden' });
  }

  return next({ name: 'access-forbidden' });
};

function checkForImplicitAccess(
  targetModule: string,
  readModules: string[]
): boolean {
  for (const module of readModules) {
    if (hasImplicitMatch(module, targetModule)) {
      return true;
    }
  }

  return false;
}

function hasImplicitMatch(module: string, targetModule: string): boolean {
  return module.startsWith(targetModule);
}

function moduleHasInstances(
  instanceType: string,
  readModules: string[]
): boolean {
  const regexExpression = new RegExp(`${instanceType}_\\d*-(read|view)`);
  const regexExpressionForSubdivision = new RegExp(
    `subdivision_\\w+_${instanceType}s-(read|view)`,
    'i'
  );
  for (const readModule of readModules) {
    if (
      regexExpression.test(readModule) ||
      regexExpressionForSubdivision.test(readModule)
    ) {
      return true;
    }
  }

  return false;
}

/**
 * @deprecated
 * This guard has been replaced by CasbinRouteGuard
 */
export const AdminRouteGuard: NavigationGuard = (to, from, next) => {
  const shouldCheck = to.meta && to.meta.adminOnly;
  if (!shouldCheck) {
    return next();
  }

  if (store.getters['rbac/isAdmin']) {
    return next();
  } else {
    return next({
      name: 'home'
    });
  }
};

export const UserAttributeRouteGuard: NavigationGuard = (to, from, next) => {
  const shouldCheck: boolean = to.meta && to.meta.checkUserAttribute;
  if (!shouldCheck) {
    return next();
  }

  const isMissingAttributeValue: boolean =
    store.getters['userAttribute/isMissingAttributeValue'];
  if (isMissingAttributeValue) {
    return next({
      name: 'UserProfile'
    });
  } else {
    return next();
  }
};
