import { moduleTreeActions } from '@/store/modules/module-tree/module-tree.actions';
import { moduleTreeMutations } from '@/store/modules/module-tree/module-tree.mutations';
import { moduleTreeState } from '@/store/modules/module-tree/module-tree.state';
import { RootState } from '@/store/store';
import { ModuleTree } from 'vuex';

export default {
  namespaced: true,
  state: moduleTreeState,
  actions: moduleTreeActions,
  mutations: moduleTreeMutations
} as ModuleTree<RootState>;
