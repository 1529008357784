import { ModuleTree } from '@/store/modules/module-tree/types/module-tree.types';
import { AxiosPromise } from 'axios';
import { axios } from './axios';
export const moduleTreeApi: {
  getModuleTree: () => AxiosPromise<ModuleTree[]>;
} = {
  getModuleTree() {
    return axios.get('module-tree');
  }
};
