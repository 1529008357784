import { NavLink, RouteDTO } from '@/jbi-shared/types/Nav';
import { navLinks } from '@/router';
import { ModuleTreeRecord } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, State } from 'vuex-class';

@Component({})
export class RbacMixin extends Vue {
  @Action('rbac/getMyRoles')
  public getMyRoles!: () => void;

  @Action('rbac/getUserPermissionsMatrix')
  public getUserPermissionsMatrix!: () => void;

  @State((state: RootState) => state.rbac.apiState.getMyRoles)
  public getMyRolesState!: ApiState;

  @State((state: RootState) => state.rbac.roles)
  public roles!: string;

  @State((state: RootState) => state.rbac.apiState.getUserPermissionsMatrix)
  public getUserPermissionsMatrixState!: ApiState;

  @Action('rolesAndPermissions/getModules')
  public getModules!: () => Promise<ModuleTreeRecord>;

  @State(({ rolesAndPermissions }: RootState) => rolesAndPermissions.modules)
  public modules!: ModuleTreeRecord[];

  @Getter('rbac/isAdmin')
  public isAdmin!: boolean;

  @Getter('rbac/rootModules')
  public rootModules!: string[];

  @Getter('rbac/readModules')
  public readModules!: string[];

  private filteredNavLinks: NavLink[] = [];

  public created() {
    if (!this.getMyRolesState.loading) {
      this.getMyRoles();
    }

    if (!this.getUserPermissionsMatrixState.loading) {
      this.getUserPermissionsMatrix();
      this.getModules();
    }
  }

  /**
   * @deprecated
   * Navigation links are now filtered based on user permissions
   */
  get navLinksOld(): NavLink[] {
    return this.isAdmin
      ? navLinks
      : navLinks.filter(
          (link) =>
            ![
              'user_administration',
              'product_administration',
              'terms_and_conditions',
              'group_administration',
              'transactions',
              'third_party_access_management',
              'roles_and_permissions'
            ].includes((link.to as Partial<RouteDTO>)?.name ?? '')
        );
  }

  public getNavLinks(): NavLink[] {
    this.filteredNavLinks = [];

    if (this.isAdmin) {
      return navLinks;
    }

    for (const link of navLinks) {
      const module = (link.to as Partial<RouteDTO>)?.module;
      const instanceType = (link.to as Partial<RouteDTO>)?.instanceType;

      if (link.url) {
        this.filteredNavLinks.push(link);
      } else if (module === 'non_module') {
        this.filteredNavLinks.push(link);
      } else if (module) {
        if (this.rootModules.length > 0) {
          if (this.rootModules.includes(module)) {
            this.filteredNavLinks.push(link);
          } else if (this.checkForImplicitAccess(module)) {
            this.filteredNavLinks.push(link);
          } else {
            if (instanceType) {
              const regexExpression = new RegExp(
                `${instanceType}_\\d*-(read|view)`,
                'i'
              );
              const regexExpressionForSubdivision = new RegExp(
                `subdivision_\\w+_${instanceType}s-(read|view)`,
                'i'
              );
              for (const readModule of this.readModules) {
                if (
                  regexExpression.test(readModule) ||
                  regexExpressionForSubdivision.test(readModule)
                ) {
                  this.filteredNavLinks.push(link);
                  break;
                }
              }
            }
          }
        }
      } else {
        this.filteredNavLinks.push(link);
      }
    }

    return this.filteredNavLinks;
  }

  private checkForImplicitAccess(targetModule: string): boolean {
    for (const module of this.readModules) {
      if (this.hasImplicitMatch(module, targetModule)) {
        return true;
      }
    }

    return false;
  }

  private hasImplicitMatch(module: string, targetModule: string): boolean {
    return module.startsWith(targetModule);
  }
}
