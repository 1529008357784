import { RootState } from '@/store/store';
import { ModuleTree } from 'vuex';
import { rolesAndPermissionsActions } from './roles-and-permissions.actions';
import { rolesAndPermissionsMutations } from './roles-and-permissions.mutations';
import { rolesAndPermissionsState } from './roles-and-permissions.state';

export default {
  namespaced: true,
  state: rolesAndPermissionsState,
  actions: rolesAndPermissionsActions,
  mutations: rolesAndPermissionsMutations
} as ModuleTree<RootState>;
