import { AxiosError } from 'axios';

export interface ApiState {
  loading: boolean;
  success: boolean;
  error: Error | AxiosError | null;
}

export const defaultApiState: ApiState = {
  loading: false,
  success: false,
  error: null
};

export interface PaginatedResponse<T> {
  itemCount: number;
  items: T[];
  next: string;
  pageCount: number;
  previous: '';
  totalItems: number;
}

export enum PaymentStatusTypes {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

interface RectangleAttr {
  width: number;
  height: number;
}
export interface GetDimension {
  width: number;
  height: number;
  border: number;
  canvas: RectangleAttr;
}
export interface CroppingRectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Image {
  x: number;
  y: number;
  width: number;
  height: number;
  resource: CanvasImageSource | null;
}
export interface ImageEditorState {
  drag: boolean;
  my: number | null;
  mx: number | null;
  image: Image;
}

export enum SortOrder {
  'ASC' = 'ASC',
  'DESC' = 'DESC'
}

export interface SortEventData {
  sortColumn: string;
  sortOrder: SortOrder;
}

export interface PaginatorSpecs {
  perPage: number;
  page: number;
}

export interface SortSpecs {
  sortColumn: string;
  sortOrder: SortOrder;
}
