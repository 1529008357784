import { defaultApiState } from '@/store/types/general.types';
import { StaticApiState } from './types/static-file.types';

export const staticFileState: StaticApiState = {
  apiState: {
    getSignedUrlForUpload: defaultApiState,
    getGroupLevelAttributeSignedUrls: defaultApiState,
  },
  signedUrlData: undefined,
  groupLevelAttributeSignedUrls: undefined,
};
