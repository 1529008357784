import {
  GetModuleTreeResponse,
  ModuleTreeState
} from '@/store/modules/module-tree/types/module-tree.types';
import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';

export const moduleTreeMutations: {
  GET_MODULE_TREE_ERROR(state: ModuleTreeState, error: AxiosError): void;
  GET_MODULE_TREE_LOADING(state: ModuleTreeState): void;
  GET_MODULE_TREE_SUCCESS(
    state: ModuleTreeState,
    data: GetModuleTreeResponse
  ): void;
} = {
  GET_MODULE_TREE_LOADING(state: ModuleTreeState) {
    state.apiState.getModuleTree = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_MODULE_TREE_SUCCESS(state: ModuleTreeState, data: GetModuleTreeResponse) {
    state.apiState.getModuleTree = {
      ...defaultApiState,
      success: true
    };
    state.moduleTree = data;
  },
  GET_MODULE_TREE_ERROR(state: ModuleTreeState, error: AxiosError) {
    state.apiState.getModuleTree = {
      ...defaultApiState,
      error
    };
  }
};
