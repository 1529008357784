import { WebSocketState } from './types/websocket.types';

export enum EVENTS {
  JOIN_ROOM = 'JOIN_ROOM',
  LEAVE_ROOM = 'LEAVE_ROOM',
  VALIDATE_IMPORTED_MEMBER_LIST_EVENT = 'VALIDATE_IMPORTED_MEMBER_LIST_EVENT',
  VALIDATE_IMPORTED_USER_INVITE_EVENT = 'VALIDATE_IMPORTED_USER_INVITE_EVENT',
}

export const websocketState: WebSocketState = {
  isConnected: false,
  error: null,
  workerJobs: [],
  usageCount: 0,
};
