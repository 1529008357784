import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';
import { UserAttributeState } from '../admin/types/group-user-attribute.types';

export const userAttributeMutations = {
  UPDATE_ATTRIBUTE_VALUE_VALIDITY(
    state: UserAttributeState,
    isMissingAttributeValue: boolean
  ): void {
    state.isMissingAttributeValue = isMissingAttributeValue;
  },

  GET_ATTRIBUTE_VALUE_VALIDITY_LOADING(state: UserAttributeState): void {
    state.apiState.getCurrentUserAttributeValueValidity = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ATTRIBUTE_VALUE_VALIDITY_SUCCESS(state: UserAttributeState): void {
    state.apiState.getCurrentUserAttributeValueValidity = {
      ...defaultApiState,
      success: true
    };
  },
  GET_ATTRIBUTE_VALUE_VALIDITY_ERROR(
    state: UserAttributeState,
    error: AxiosError
  ): void {
    state.apiState.getCurrentUserAttributeValueValidity = {
      ...defaultApiState,
      error
    };
  }
};
