import { profileState } from './profile.state';
import { profileGetters } from './profile.getters';
import { profileActions } from './profile.actions';
import { profileMutations } from './profile.mutations';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: profileState,
  getters: profileGetters,
  actions: profileActions,
  mutations: profileMutations,
} as ModuleTree<RootState>;
