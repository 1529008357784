import { defaultApiState } from '@/store/types/general.types';
import { ApplicationState } from './types/application.types';

export const applicationState: ApplicationState = {
  apiState: {
    getApplications: defaultApiState,
    getProducts: defaultApiState,
    getProduct: defaultApiState,
    getAdministrationProduct: defaultApiState,
    createProduct: defaultApiState,
    updateProduct: defaultApiState,
    getLicenseModels: defaultApiState,
    disableProduct: defaultApiState,
    deletedProduct: defaultApiState
  },
  administrationProducts: undefined,
  productApplications: undefined,
  products: undefined,
  product: undefined,
  licenseModels: undefined,
  currentProduct: undefined,
  currentProductStatus: undefined
};
