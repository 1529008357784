import { AuthState } from './types/auth.types';
import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';

export const authMutations = {
  CHECK_SESSION_HEALTH_LOADING(state: AuthState) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      loading: true,
    };
  },
  CHECK_SESSION_HEALTH_SUCCESS(state: AuthState) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      success: true,
    };
    state.isLoggedIn = true;
  },
  CHECK_SESSION_HEALTH_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      error,
    };
    state.isLoggedIn = false;
  },
};
