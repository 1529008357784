import { checkForImplicitAccess } from '@/jbi-shared/util/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import {
  ModuleTreeRecord,
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import store, { RootState } from '@/store/store';

/**
 * @param action
 * The action associated with the module name(s)
 * @param moduleNames
 * Module names can be an array as a permission can be dependent on access to
 * multiple modules
 * @param entityType
 * Entity type is only applicable for modules that have instances
 * @param subdivisionName
 * Subdivision name is only applicable for modules that have instances
 * @param instanceId
 * Required when checking for specific access given to an instance
 * @param resourceExceptions
 * Required for situations where a user has full access to a module but
 * restrictions are in place for certain groups or certain submodules in
 * groups
 * @param skipImplicitCheck
 * Needed for situations where you want the exact module to be present in the
 * matrix
 */
export function isUserAllowed(
  action: PermissionsMatrixActionsEnum,
  moduleNames: string | string[],
  entityType?: EntityTypes,
  subdivisionName?: string,
  instanceId?: number,
  resourceExceptions?: ResourceExceptions,
  skipImplicitCheck?: boolean
) {
  if (typeof moduleNames === 'string') {
    moduleNames = [moduleNames];
  }

  /**
   * Check if entity has specific exceptions set (needed for cases where a user
   * has full access to groups module but is restricted from accessing specific
   * groups/submodules in specific groups).
   */
  if (instanceId && entityType && resourceExceptions) {
    for (const module of moduleNames) {
      if (
        resourceExceptions.granted.includes(
          getModuleAsInstance(module, entityType, instanceId)
        )
      ) {
        return true;
      }

      if (
        resourceExceptions.blocked.includes(
          getModuleAsInstance(module, entityType, instanceId)
        )
      ) {
        return false;
      }
    }
  }

  let permissions: string[] = [];
  const state = store.state as RootState;

  /** Check for admin access - super admin role */
  const roles: string[] | null = state.rbac.roles;
  if (roles && roles.includes('super-admin-role')) {
    return true;
  }

  // Filter matrix by action
  if (state.rbac.userPermissionsMatrix) {
    permissions = state.rbac.userPermissionsMatrix[action];
    if (!permissions) {
      return false;
    }
  }

  /**
   * Check if user has direct access to the module in question, e.g. user is
   * trying to access group_administration-groups-read_groups and the matrix
   * has the very same module in the read array.
   */
  for (const module of moduleNames) {
    if (permissions.includes(module)) {
      return true;
    }
  }

  /**
   * Check if user has implicit access, e.g. user is trying to access
   * group_administration-groups-read_groups and their matrix contains
   * group_administration. This means they have higher access than what is
   * required.
   */
  for (const module of moduleNames) {
    if (!skipImplicitCheck && checkForImplicitAccess(module, permissions)) {
      return true;
    }
  }

  /**
   * Check if user has access through a subdivision of a module, e.g. user
   * only has read access to JBI groups (subdivision_jbi_groups-read_groups).
   */
  if (entityType) {
    for (const module of moduleNames) {
      if (subdivisionName) {
        if (
          checkForSubdivisionAccess(
            module,
            entityType,
            permissions,
            subdivisionName,
            skipImplicitCheck
          )
        ) {
          return true;
        }
      }
    }
  }

  /**
   * Failing everything, check if user has access through an instance of
   * that module, e.g. group_1-read_groups.
   */
  if (entityType && instanceId) {
    for (const module of moduleNames) {
      if (checkForInstanceAccess(module, entityType, permissions, instanceId)) {
        return true;
      }
    }
  }

  return false;
}

function checkForSubdivisionAccess(
  moduleUserTryingToAccess: string,
  entityType: EntityTypes,
  permissions: string[],
  subdivisionName: string,
  skipImplicitCheck = false
): boolean {
  const regexExpressionForSubdivision = new RegExp(
    `subdivision_\\w+_${entityType}s-`,
    'i'
  );
  const subdivisionModules = permissions.filter((module) =>
    regexExpressionForSubdivision.test(module)
  );

  const moduleSplit = moduleUserTryingToAccess.split('-');
  /**
   * Bespoke code for entity type groups. The intent here is to turn
   * 'group_administration-groups-<some module>' to
   * 'subdivision_<some group type>_groups-<some module>'.
   */
  if (entityType === EntityTypes.GROUP) {
    moduleSplit.splice(1, 1);
  }

  // 'Sluggify' group type name
  const formattedSubdivisionName = subdivisionName
    .toLowerCase()
    .split(' ')
    .join('_');

  moduleSplit[0] = `subdivision_${formattedSubdivisionName}_${entityType}s`;
  const moduleAsSubdivision = moduleSplit.join('-');

  if (subdivisionModules.includes(moduleAsSubdivision)) {
    return true;
  }

  if (!skipImplicitCheck) {
    return checkForImplicitAccess(moduleAsSubdivision, subdivisionModules);
  }

  return false;
}

function checkForInstanceAccess(
  moduleUserTryingToAccess: string,
  entityType: EntityTypes,
  permissions: string[],
  instanceId: number
) {
  const regexExpression = new RegExp(`${entityType}_\\d*-`);
  const instanceModules = permissions.filter((module) =>
    regexExpression.test(module)
  );

  const moduleSplit = moduleUserTryingToAccess.split('-');
  /**
   * Bespoke code for entity type groups. The intent here is to turn
   * 'group_administration-groups-<some module>' to
   * 'group_<id>-<some module>'.
   */
  if (entityType === EntityTypes.GROUP) {
    moduleSplit.splice(1, 1);
  }

  moduleSplit[0] = `${entityType}_${instanceId}`;
  const moduleAsInstance = moduleSplit.join('-');

  return (
    instanceModules.includes(moduleAsInstance) ||
    checkForImplicitAccess(moduleAsInstance, instanceModules)
  );
}

function getModuleAsInstance(
  moduleUserTryingToAccess: string,
  entityType: EntityTypes,
  instanceId: number
): string {
  const moduleSplit = moduleUserTryingToAccess.split('-');
  /**
   * Bespoke code for entity type groups. The intent here is to turn
   * 'group_administration-groups-<some module>' to
   * 'group_<id>-<some module>'.
   */
  if (entityType === EntityTypes.GROUP) {
    moduleSplit.splice(1, 1);
  }

  moduleSplit[0] = `${entityType}_${instanceId}`;
  return moduleSplit.join('-');
}

function checkForParentModuleAccess(
  userPermissions: string[],
  moduleRecords: ModuleTreeRecord[],
  parentIds: number[],
  subdivisionOrInstance?: string
): boolean {
  const parentModuleNames: string[] = moduleRecords
    .filter((module) => parentIds.includes(module.id))
    .map((module) => module.name);

  /** check parent permission */
  const parentHasPermission = parentModuleNames.some((parentModule) => {
    let moduleName = parentModule;
    if (subdivisionOrInstance) {
      const rootModule: string = parentModule.split('-').slice(0, 2).join('-');
      moduleName = parentModule.replace(rootModule, subdivisionOrInstance);
    }
    return userPermissions.includes(moduleName);
  });

  if (parentHasPermission) {
    return true;
  } else {
    /**
     * Check for parent permission:
     * Traverse tree towards root (from child to parent), to check parent permissions for the module.
     * As module may have permissions defined for the parent, child inherits permissions from parent.
     * Check until reach the root of tree or find the required permissions.
     */
    const parentModuleIds: number[] = moduleParentIds(
      parentModuleNames,
      moduleRecords
    );
    if (parentModuleIds.length > 0) {
      return checkForParentModuleAccess(
        userPermissions,
        moduleRecords,
        parentModuleIds,
        subdivisionOrInstance
      );
    }
  }

  return false;
}

function checkForDirectAccess(
  moduleNames: string[],
  userPermissions: string[],
  moduleRecords: ModuleTreeRecord[] | undefined
): boolean {
  const permission: boolean = moduleNames.some((module) => {
    return userPermissions.includes(module);
  });

  if (permission) {
    return permission;
  } else if (moduleRecords && moduleRecords.length > 0) {
    /** check for parent permission */
    const parentModuleIds = moduleParentIds(moduleNames, moduleRecords);
    if (parentModuleIds.length > 0) {
      return checkForParentModuleAccess(
        userPermissions,
        moduleRecords,
        parentModuleIds
      );
    }
  }

  return false;
}

function checkForImplicitAccessForMultipleModules(
  moduleNames: string[],
  action: PermissionsMatrixActionsEnum,
  userPermissions: string[]
): boolean {
  return moduleNames.some((module) => {
    const regexExpression = new RegExp(`${module}(-\\w*)*-${action}`, 'i');
    for (const permission of userPermissions) {
      if (checkForImplicitAccess(permission, userPermissions)) {
        return true;
      }
    }
  });
}

function checkForAccessThroughSubdivision(
  moduleNames: string[],
  userPermissions: string[],
  action: PermissionsMatrixActionsEnum,
  entityType: EntityTypes,
  subdivisionType: string,
  moduleRecords: ModuleTreeRecord[] | undefined
): boolean {
  const subdivision: string = `subdivision_${subdivisionType.toLowerCase()}_${entityType}s`;
  const subdivisionModules: string[] = moduleNames.map((module) => {
    const rootModule: string = module.split('-').slice(0, 2).join('-');
    return module.replace(rootModule, subdivision);
  });
  const permission = subdivisionModules.some((module) => {
    return userPermissions.includes(module);
  });

  if (permission) {
    return permission;
  } else if (
    checkForImplicitAccessForMultipleModules(
      subdivisionModules,
      action,
      userPermissions
    )
  ) {
    return true;
  } else if (moduleRecords && moduleRecords.length > 0) {
    /** check for parent permission */
    const parentModuleIds = moduleParentIds(moduleNames, moduleRecords);
    if (parentModuleIds.length > 0) {
      return checkForParentModuleAccess(
        userPermissions,
        moduleRecords,
        parentModuleIds,
        subdivision
      );
    }
  }

  return false;
}

function checkForAccessThroughInstance(
  moduleNames: string[],
  userPermissions: string[],
  action: PermissionsMatrixActionsEnum,
  instance: string,
  moduleRecords: ModuleTreeRecord[] | undefined
): boolean {
  const instanceModules: string[] = moduleNames.map((module) => {
    const rootModule: string = module.split('-').slice(0, 2).join('-');
    return module.replace(rootModule, instance);
  });
  const permission = instanceModules.some((module) => {
    return userPermissions.includes(module);
  });

  if (permission) {
    return permission;
  } else if (
    checkForImplicitAccessForMultipleModules(
      instanceModules,
      action,
      userPermissions
    )
  ) {
    return true;
  } else if (moduleRecords && moduleRecords.length > 0) {
    /** check for parent permission */
    const parentModuleIds = moduleParentIds(moduleNames, moduleRecords);
    if (parentModuleIds.length > 0) {
      return checkForParentModuleAccess(
        userPermissions,
        moduleRecords,
        parentModuleIds,
        instance
      );
    }
  }

  return false;
}

function moduleParentIds(
  moduleNames: string[],
  moduleRecords: ModuleTreeRecord[]
): number[] {
  return moduleRecords
    .filter((module) => moduleNames.includes(module.name))
    .map((module) => module.parentId);
}

export function getGroupsUserHasAccessTo(modules: string[]) {
  const groupIds: number[] = [];
  const regexExpression = new RegExp(`^group_(\\d*){0,9}-(read|view)`);
  if (modules) {
    for (const module of modules) {
      if (regexExpression.test(module)) {
        const regexExecResult = regexExpression.exec(module);
        if (regexExecResult) {
          groupIds.push(parseInt(regexExecResult[1], 10));
        }
      }
    }
  }

  return Array.from(new Set(groupIds));
}

function getInstancesUserHasAccessTo(
  modules: string[],
  entityType: EntityTypes
) {
  const instanceIds: number[] = [];
  const regexExpression = new RegExp(`^${entityType}_(\\d*){0,9}-`);
  if (modules) {
    for (const module of modules) {
      if (regexExpression.test(module)) {
        const regexExecResult = regexExpression.exec(module);
        if (regexExecResult) {
          instanceIds.push(parseInt(regexExecResult[1], 10));
        }
      }
    }
  }

  return Array.from(new Set(instanceIds));
}

export function getSubdivisionsUserHasAccessTo(
  modules: string[],
  entityType: EntityTypes
) {
  const groupTypeIds: string[] = [];
  const regexExpression = new RegExp(`^subdivision_(\\w+)_${entityType}s-`);
  if (modules) {
    for (const module of modules) {
      if (regexExpression.test(module)) {
        const regexExecResult = regexExpression.exec(module);
        if (regexExecResult) {
          groupTypeIds.push(regexExecResult[1]);
        }
      }
    }
  }

  return Array.from(new Set(groupTypeIds));
}

/**
 * Check for module access
 * Few modules may have access through instances
 * For eg: group list,
 * user may have access to some instances(groups), hence he can view list
 */
export function checkForImplicitModuleAccess(
  action: PermissionsMatrixActionsEnum,
  moduleName: string
) {
  let permissions: string[] = [];
  const state = store.state as RootState;

  /** Check for admin access - super admin role */
  const roles: string[] | null = state.rbac.roles;
  if (roles && roles.includes('super-admin-role')) {
    return true;
  }

  // Filter matrix by action
  if (state.rbac.userPermissionsMatrix) {
    permissions = state.rbac.userPermissionsMatrix[action];
    if (!permissions) {
      return false;
    }
  }

  /**
   * Check if user has direct access to the module in question, e.g. user is
   * trying to access group_administration-groups-read_groups and the matrix
   * has the very same module in the read array.
   */
  if (permissions.includes(moduleName)) {
    return true;
  }

  /**
   * Check if user has implicit access, e.g. user is trying to access
   * group_administration-groups-read_groups and their matrix contains
   * group_administration. This means they have higher access than what is
   * required.
   */
  if (checkForImplicitAccess(moduleName, permissions)) {
    return true;
  }

  if (checkForImplicitInstanceOrSubdivisionAccess(moduleName, permissions)) {
    return true;
  }

  return false;
}

function checkForImplicitInstanceOrSubdivisionAccess(
  moduleName: string,
  permissions: string[]
): boolean {
  /**
   * Check if user has access through a subdivision or through an instance of a module.
   * e.g. for subdiviison -  user may have access to JBI groups (subdivision_jbi_groups-read_groups)
   * e.g. for instance - group_1-read_groups(group 1)
   */
  const moduleArray = moduleName.split('-');
  const moduleNames = [];
  for (let i = 2; i < moduleArray.length; i++) {
    moduleNames.push(moduleArray.slice(2, i + 1).join('-'));
  }
  for (const module of moduleNames) {
    if (permissions.some((permission) => permission.includes(module))) {
      return true;
    }
  }
  return false;
}
