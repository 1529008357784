import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';
import {
  GetSignedUrlForUploadResponsePayload,
  StaticApiState,
} from './types/static-file.types';

export const staticFileMutations = {
  /*
   ** getSignedUrlForUpload
   */
  GET_SIGNED_URL_FOR_UPLOAD_LOADING(state: StaticApiState) {
    state.apiState.getSignedUrlForUpload = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_SIGNED_URL_FOR_UPLOAD_SUCCESS(
    state: StaticApiState,
    payload: GetSignedUrlForUploadResponsePayload,
  ) {
    state.apiState.getSignedUrlForUpload = {
      ...defaultApiState,
      success: true,
    };
    state.signedUrlData = payload;
  },
  GET_SIGNED_URL_FOR_UPLOAD_ERROR(state: StaticApiState, error: AxiosError) {
    state.apiState.getSignedUrlForUpload = {
      ...defaultApiState,
      error,
    };
  },

  GET_GROUP_LEVEL_ATTRIBUTE_UPLOADABLES_SIGNED_URL_LOADING(
    state: StaticApiState,
  ) {
    state.apiState.getGroupLevelAttributeSignedUrls = {
      ...defaultApiState,
      loading: true,
    };
    state.groupLevelAttributeSignedUrls = undefined;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_UPLOADABLES_SIGNED_URL_SUCCESS(
    state: StaticApiState,
    data: GetSignedUrlForUploadResponsePayload[],
  ) {
    state.apiState.getGroupLevelAttributeSignedUrls = {
      ...defaultApiState,
      success: true,
    };
    state.groupLevelAttributeSignedUrls = data;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_UPLOADABLES_SIGNED_URL_ERROR(
    state: StaticApiState,
    error: AxiosError,
  ) {
    state.apiState.getGroupLevelAttributeSignedUrls = {
      ...defaultApiState,
      error,
    };
  },
};
