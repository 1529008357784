import Buefy from 'buefy';
import { get } from 'lodash';
import PortalVue from 'portal-vue';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueClipboard from 'vue-clipboard2';
import frag from 'vue-frag';
// @ts-ignore
import Fragment from 'vue-fragment';
import VueHeadful from 'vue-headful';
import * as VueQuillEditor from 'vue-quill-editor';
import VueRx from 'vue-rx';
import { axios } from './api/axios';
import App from './App.vue';
import router from './router';
import store from './store/store';
import { getPageTitle } from './utils/page-title.util';
import { moneyParser } from './utils/parser.util';
import { RbacMixin } from './utils/rbac.mixin';

Vue.prototype.VUE_APP_BACKEND_HOSTNAME = process.env.VUE_APP_BACKEND_HOSTNAME;
Vue.prototype.VUE_APP_ENV = process.env.VUE_APP_ENV;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOSTNAME;

Vue.use(VueAxios, axios);
Vue.use(Buefy);
Vue.use(VeeValidate, { classes: true });
Vue.use(VueRx);
Vue.use(VueClipboard);
Vue.use(PortalVue);

/**
 * TODO: There is an issue with this package not being able to work with v-for
 * https://github.com/Thunberg087/vue-fragment/issues/62
 * Should use this package again when the fix has been applied
 */
Vue.use(Fragment.Plugin);

// Temporary library we use to use fragment in vue 2.6.6
Vue.directive('frag', frag);

Vue.use(VueQuillEditor as any);

Vue.component('VueHeadful', VueHeadful);

// log for debugging
// tslint:disable-next-line
Vue.prototype.log = console.log;
Vue.prototype.moneyParser = moneyParser;
// utilify for page title
Vue.prototype.getPageTitle = getPageTitle;
Vue.prototype._get = get;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mixins: [RbacMixin]
}).$mount('#app');
