import { axios } from './axios';

export const rbacApi = {
  async getMyRoles() {
    return axios.get('/me/roles');
  },
  async getUserPermissionMatrix() {
    return axios.get('/user-permissions-matrix');
  }
};
