import * as NProgress from 'nprogress';
import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

// configure Nprogress
const loadingBarSelector = '#loading-bar';
const int = setInterval(() => {
  if (document.querySelector(loadingBarSelector)) {
    NProgress.configure({ parent: loadingBarSelector });
    clearInterval(int);
  }
}, 100);

let activeRequest: number = 0;

export const nprogressRequestInterceptor = (config: AxiosRequestConfig) => {
  activeRequest++;
  NProgress.start();
  return config;
};

export const nprogressResponseInterceptor = (response: AxiosResponse) => {
  activeRequest--;
  if (!activeRequest) {
    NProgress.done();
  }
  return response;
};

export const nprogressErrorInterceptor = (error: AxiosError) => {
  activeRequest--;
  if (!activeRequest) {
    NProgress.done();
  }
  return Promise.reject(error);
};
