import { GenericSuccessMessage } from '@/jbi-shared/interfaces/generic-success-message.interface';
import {
  CreateRolePayload,
  DeleteGroupsFromRoleQueryParams,
  DeleteInvitationsFromRoleQueryParams,
  DeleteUsersFromRoleQueryParams,
  GetRoleResourcesQueryParams,
  GetRolesQueryParams,
  RoleSansTree,
  RoleWithGroups,
  RoleWithTree,
  RoleWithUsers,
  UpdateRolePayload
} from '@/store/modules/role/types/role.types';
import { PaginatedResponse } from '@/store/types/general.types';
import { AxiosPromise } from 'axios';
import { axios } from './axios';

export const roleApi: {
  getRoles: (
    payload: GetRolesQueryParams
  ) => AxiosPromise<PaginatedResponse<RoleSansTree>>;
  getRoleById: (id: number) => AxiosPromise<RoleWithTree>;
  createRole: (
    payload: CreateRolePayload
  ) => AxiosPromise<GenericSuccessMessage>;
  updateRole: (
    payload: UpdateRolePayload
  ) => AxiosPromise<GenericSuccessMessage>;
  deleteRole: (id: number) => AxiosPromise<GenericSuccessMessage>;
  getUsersAssignedToRole: (
    params: GetRoleResourcesQueryParams
  ) => AxiosPromise<RoleWithUsers>;
  getGroupsAssignedToRole: (
    params: GetRoleResourcesQueryParams
  ) => AxiosPromise<RoleWithGroups>;
  deleteUsersFromRole: (
    params: DeleteUsersFromRoleQueryParams
  ) => AxiosPromise<GenericSuccessMessage>;
  deleteGroupsFromRole: (
    params: DeleteGroupsFromRoleQueryParams
  ) => AxiosPromise<GenericSuccessMessage>;
  deleteInvitationsFromRole: (
    params: DeleteInvitationsFromRoleQueryParams
  ) => AxiosPromise<GenericSuccessMessage>;
} = {
  getRoles(payload: GetRolesQueryParams) {
    return axios.get('roles', {
      params: {
        limit: payload.limit,
        page: payload.page,
        searchQuery: payload.searchQuery,
        sortOrder: payload.sortOrder
      }
    });
  },
  getRoleById(id: number) {
    return axios.get(`roles/${id}`);
  },
  createRole(payload: CreateRolePayload) {
    return axios.post('roles', { ...payload });
  },
  updateRole(payload: UpdateRolePayload) {
    return axios.put(`roles/${payload.id}`, { ...payload });
  },
  deleteRole(id: number) {
    return axios.delete(`roles/${id}`);
  },
  getUsersAssignedToRole(params: GetRoleResourcesQueryParams) {
    return axios.get(`roles/${params.id}/users`, {
      params: {
        limit: params.limit,
        page: params.page,
        searchQuery: params.searchQuery
      }
    });
  },
  getGroupsAssignedToRole(params: GetRoleResourcesQueryParams) {
    return axios.get(`roles/${params.id}/groups`, {
      params: {
        limit: params.limit,
        page: params.page,
        searchQuery: params.searchQuery
      }
    });
  },
  deleteUsersFromRole(params: DeleteUsersFromRoleQueryParams) {
    return axios.delete(`roles/${params.id}/users`, {
      params: {
        userIds: params.userIds
      }
    });
  },
  deleteGroupsFromRole(params: DeleteGroupsFromRoleQueryParams) {
    return axios.delete(`roles/${params.id}/groups`, {
      params: {
        groupIds: params.groupIds
      }
    });
  },
  deleteInvitationsFromRole(params: DeleteInvitationsFromRoleQueryParams) {
    return axios.delete(`roles/${params.id}/invitations`, {
      params: {
        invitationIds: params.invitationIds
      }
    });
  }
};
