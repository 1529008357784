import { Store } from 'vuex';
import {
  SumariState,
  ConvertWKUserPayload,
} from '@/store/modules/sumari/sumari.types';
import { sumariApi } from '@/api/sumari.api';

export const sumariActions = {
  async getWkSubscription(store: Store<SumariState>) {
    try {
      store.commit('GET_WK_SUBSCRIPTION_LOADING');

      const response = await sumariApi.getWkSubscription();

      store.commit('GET_WK_SUBSCRIPTION_SUCCESS', response.data);
      return response;
    } catch (error) {
      store.commit('GET_WK_SUBSCRIPTION_ERROR', error);
    }
  },
  async convertToMyJbiUser(
    store: Store<SumariState>,
    params: ConvertWKUserPayload,
  ) {
    try {
      store.commit('CONVER_TO_MYJBI_USER_LOADING');

      await sumariApi.convertWkUserToInternal(params);

      store.commit('CONVER_TO_MYJBI_USER_SUCCESS');
    } catch (error) {
      store.commit('CONVER_TO_MYJBI_USER_ERROR');
    }
  },
};
