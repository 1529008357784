import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';
import { AuditLog, AuditLogState, Pagination } from './types/audit-log.types';

export const auditLogMutations = {
  GET_AUDIT_LOG_LOADING(state: AuditLogState) {
    state.apiState.getAuditLogs = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_AUDIT_LOG_SUCCESS(state: AuditLogState, payload: Pagination<AuditLog>) {
    state.apiState.getAuditLogs = {
      ...defaultApiState,
      success: true,
    };
    state.auditLogs = payload;
  },
  GET_AUDIT_LOG_ERROR(state: AuditLogState, error: AxiosError) {
    state.apiState.getAuditLogs = {
      ...defaultApiState,
      error,
    };
  },
  EXPORT_CSV_AUDIT_LOG_LOADING(state: AuditLogState) {
    state.apiState.exportAuditLogsCsv = {
      ...defaultApiState,
      loading: true,
    };
  },
  EXPORT_CSV_AUDIT_LOG_SUCCESS(state: AuditLogState) {
    state.apiState.exportAuditLogsCsv = {
      ...defaultApiState,
      success: true,
    };
  },
  EXPORT_CSV_AUDIT_LOG_ERROR(state: AuditLogState, error: AxiosError) {
    state.apiState.exportAuditLogsCsv = {
      ...defaultApiState,
      error,
    };
  },
  EXPORT_PDF_AUDIT_LOG_LOADING(state: AuditLogState) {
    state.apiState.exportAuditLogsPdf = {
      ...defaultApiState,
      loading: true,
    };
  },
  EXPORT_PDF_AUDIT_LOG_SUCCESS(
    state: AuditLogState,
    payload: Pagination<AuditLog>,
  ) {
    state.apiState.exportAuditLogsPdf = {
      ...defaultApiState,
      success: true,
    };
    state.pdfAuditLogs = payload;
  },
  EXPORT_PDF_AUDIT_LOG_ERROR(state: AuditLogState, error: AxiosError) {
    state.apiState.exportAuditLogsPdf = {
      ...defaultApiState,
      error,
    };
  },
};
