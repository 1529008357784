import { Store } from 'vuex';
import { authApi } from '@/api/auth.api';
import { AuthState } from './types/auth.types';

export const authActions = {
  async checkSessionHealth(store: Store<AuthState>) {
    try {
      store.commit('CHECK_SESSION_HEALTH_LOADING');

      await authApi.checkSessionHealth();

      store.commit('CHECK_SESSION_HEALTH_SUCCESS');
    } catch (error) {
      store.commit('CHECK_SESSION_HEALTH_ERROR', error);
    }
  },
};
