import { defaultApiState } from '@/store/types/general.types';
import { RolesAndPermissionsState } from './types/roles-and-permissions.types';

export const rolesAndPermissionsState: RolesAndPermissionsState = {
  apiState: {
    getModules: defaultApiState,
    getPermissionsForResource: defaultApiState,
    getRolesAndExceptionsForResource: defaultApiState,
    addUsersToRole: defaultApiState,
    addGroupsToRole: defaultApiState,
    getExceptionsByModule: defaultApiState,
    getResourceExceptionsByModule: defaultApiState,
    getResourceInstancesExceptions: defaultApiState,
    getGroupExceptions: defaultApiState,
    createException: defaultApiState,
    updateException: defaultApiState,
    deleteExceptions: defaultApiState
  },
  modules: undefined,
  permissionsForResource: undefined,
  rolesAndExceptionsForResource: undefined,
  groupExceptions: undefined,
  moduleExceptions: undefined,
  resourceExceptions: undefined,
  selectedGroupsForVueTreeselect: undefined,
  resourceInstancesExceptions: undefined
};
