import { ApiState, defaultApiState } from '@/store/types/general.types';
import { OrderState } from './order.types';

export const orderState: OrderState = {
  apiState: {
    getMyOrders: defaultApiState,
    getOrder: defaultApiState,
    createOrder: defaultApiState,
    updateOrder: defaultApiState,
    retryPayment: defaultApiState,
  },
  authKey: undefined,
  orders: undefined,
  order: undefined,
};
