import { GroupLevelAttributeUploadableSignedUrlRequestPayload } from '../store/modules/admin/types/group-level-attribute.types';
import { GetSignedUrlForUploadRequestPayload } from '../store/modules/static-file/types/static-file.types';
import { axios } from './axios';

export const staticFileApi = {
  async getSignedUrlForUpload(params: GetSignedUrlForUploadRequestPayload) {
    return axios.get('signed-url-for-upload', { params });
  },

  getSignedUrlForGroupLevelAttributeUploadable(
    payload: GroupLevelAttributeUploadableSignedUrlRequestPayload[]
  ) {
    return axios.get('group-level-attribute-uploadable', { params: payload });
  }
};
