import { defaultApiState } from '@/store/types/general.types';
import { SumariState } from '@/store/modules/sumari/sumari.types';

export const sumariMutations = {
  GET_WK_SUBSCRIPTION_LOADING(state: SumariState) {
    state.apiState.getWkSubscription = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_WK_SUBSCRIPTION_SUCCESS(state: SumariState, data: any[]) {
    state.apiState.getWkSubscription = {
      ...defaultApiState,
      success: true,
    };
    state.wkSubscription = data;
  },
  GET_WK_SUBSCRIPTION_ERROR(state: SumariState, error: Error) {
    state.apiState.getWkSubscription = {
      ...defaultApiState,
      error,
    };
  },
  CONVER_TO_MYJBI_USER_LOADING(state: SumariState) {
    state.apiState.covertToMyJbiUser = {
      ...defaultApiState,
      loading: true,
    };
  },
  CONVER_TO_MYJBI_USER_SUCCESS(state: SumariState) {
    state.apiState.covertToMyJbiUser = {
      ...defaultApiState,
      success: true,
    };
  },
  CONVER_TO_MYJBI_USER_ERROR(state: SumariState, error: Error) {
    state.apiState.covertToMyJbiUser = {
      ...defaultApiState,
      error,
    };
  },
};
