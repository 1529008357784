import { ExtendProductSubscriptionPayload } from '@/store/modules/subscription/subscription.state';
import { axios } from './axios';

export const subscriptionApi = {
  async getMySubscriptions() {
    return axios.get('me/subscriptions');
  },
  async extendProductSubscription(data: ExtendProductSubscriptionPayload) {
    const {
      productId,
      exclusionDuration,
      exclusionDurationType,
      applicationId
    } = data;
    return axios.post(`subscriptions/${productId}`, {
      applicationId,
      exclusionDuration,
      exclusionDurationType
    });
  }
};
