import {
  ExportFileAuditLogPayload,
  GetAuditLogPayload
} from '@/store/modules/audit-log/types/audit-log.types';
import { axios } from './axios';

export const auditLogApi = {
  getAuditLogs(params: GetAuditLogPayload) {
    return axios.get('audit-logs', {
      params: {
        ...params,
        page: params.page || 1,
        limit: params.limit || 10
      }
    });
  },
  exportAuditLogsCsv(params: ExportFileAuditLogPayload) {
    return axios.get('audit-logs/export-to-csv', {
      params: {
        ...params
      }
    });
  },
  exportAuditLogsPdf(params: ExportFileAuditLogPayload) {
    return axios.get('audit-logs', {
      params: {
        ...params
      }
    });
  }
};
