import { adminApi } from '@/api/admin.api';
import { Store } from 'vuex';
import { UserAttributeState } from '../admin/types/group-user-attribute.types';

export const userAttributeActions = {
  updateAttributeValueValidity(
    store: Store<UserAttributeState>,
    isMissingAttributeValue: boolean
  ): void {
    store.commit('UPDATE_ATTRIBUTE_VALUE_VALIDITY', isMissingAttributeValue);
  },

  async getAttributeValueValidity(
    store: Store<UserAttributeState>
  ): Promise<void> {
    try {
      store.commit('GET_ATTRIBUTE_VALUE_VALIDITY_LOADING');

      const { data } = await adminApi.getCurrentUserAttributeValueValidity();
      store.commit('GET_ATTRIBUTE_VALUE_VALIDITY_SUCCESS');
      store.commit(
        'UPDATE_ATTRIBUTE_VALUE_VALIDITY',
        typeof data === 'boolean' ? !data : data
      );
    } catch (error) {
      store.commit('GET_ATTRIBUTE_VALUE_VALIDITY_ERROR', error);
      store.commit('UPDATE_ATTRIBUTE_VALUE_VALIDITY', false);
      throw error;
    }
  }
};
