import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';
import {
  applicationState,
  applicationGetters,
  applicationActions,
  applicationMutations,
} from './';

export default {
  namespaced: true,
  state: applicationState,
  getters: applicationGetters,
  actions: applicationActions,
  mutations: applicationMutations,
} as ModuleTree<RootState>;
