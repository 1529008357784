import { Job } from 'bull';
import { Store } from 'vuex';
import { WebSocketState } from './types/websocket.types';

export const websocketActions = {
  async socketConnect(store: Store<WebSocketState>) {
    store.commit('SOCKET_CONNECT');
  },
  async socketDisconnect(store: Store<WebSocketState>) {
    store.commit('SOCKET_DISCONNECT');
  },
  async socketException(store: Store<WebSocketState>, payload: Error) {
    store.commit('SOCKET_EXCEPTION', payload);
  },
  async addWorkerJob(store: Store<WebSocketState>, payload: Job) {
    store.commit('ADD_WORKER_JOB', payload);
  },
  async removeWorkerJob(store: Store<WebSocketState>, jobId?: number) {
    store.commit('REMOVE_WORKER_JOB', jobId);
  },
  async markWorkerJobAsCompleted(store: Store<WebSocketState>, payload: Job) {
    store.commit('MARK_WORKER_JOB_AS_COMPLETED', payload);
  },
  async markWorkerJobAsClosed(store: Store<WebSocketState>, payload: Job) {
    store.commit('MARK_WORKER_JOB_AS_CLOSED', payload);
  },
  async markWorkerJobAsErrored(store: Store<WebSocketState>, payload: Job) {
    store.commit('MARK_WORKER_JOB_AS_ERRORED', payload);
  },
  add1UsageCount(store: Store<WebSocketState>) {
    store.commit('ADD_1_USAGE_COUNT');
  },
  minus1UsageCount(store: Store<WebSocketState>) {
    store.commit('MINUS_1_USAGE_COUNT');
  },
};
