export function checkForImplicitAccess(
  targetModule: string,
  modules: string[],
): boolean {
  for (const module of modules) {
    if (hasImplicitMatch(targetModule, module)) {
      return true;
    }
  }

  return false;
}

function hasImplicitMatch(targetModule: string, module: string): boolean {
  if (targetModule.startsWith(module)) {
    return true;
  }

  const moduleSegments = module.split('-');
  const targetSegments = targetModule.split('-');

  // Ensure module has enough segments to be a potential parent
  if (targetSegments.length > moduleSegments.length) {
    return false;
  }

  // Check if module segments are a prefix of the target segments
  for (let i = 0; i < targetSegments.length; i++) {
    if (moduleSegments[i] !== targetSegments[i]) {
      return false;
    }
  }

  return true;
}
