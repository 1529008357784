import { MyjbiGroupDetail } from '@/jbi-shared/types/myjbi-group.types';
import { ApiState, defaultApiState } from '@/store/types/general.types';

export interface Profile {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  birthdate: string;
  organisation: string;
  country: string;
  specialty: null;
  researchgate: string;
  shortbio: string;
  source: string;
  createdBy: null;
  updatedBy: null;
  createdAt: string;
  updatedAt: string;
  email: string;
  username: string;
  profileImage?: ProfileImage;
  profileImageUrl: string;
  userProfile?: Profile;
}

export const BaseUserDetailsSlugs: string[] = [
  'email',
  'first-name',
  'last-name'
];

export enum BaseUserAttributeId {
  EMAIL = 1,
  FIRST_NAME = 2,
  LAST_NAME = 3
}

export const BaseUserProfileSlugs: string[] = [
  'user-profile-email-verified-1001',
  'user-profile-user-status-1002',
  'user-profile-username-1003'
];

export const BaseAttributeSlugs: string[] = [
  ...BaseUserDetailsSlugs,
  ...BaseUserProfileSlugs
];

interface ProfileImage {
  createdAt: string;
  id: number;
  imageUri: string;
  thumbnailUri: string;
  updatedAt: string;
}

export type ProfileUpdatePayload = Partial<
  Pick<
    Profile,
    Exclude<
      keyof Profile,
      'id' | 'createdBy' | 'updatedBy' | 'createdAt' | 'updatedAt'
    >
  >
>;

export interface ProfileState {
  apiState: {
    getMe: ApiState;
    updateMe: ApiState;
    updateMyPassword: ApiState;
    updateUserProfileImage: ApiState;
    getProfileImageSignedUrlState: ApiState;
    getGroupList: ApiState;
    getDestinationAppUrl: ApiState;
    removeDestinationApp: ApiState;
  };
  profile: Profile | null;
  password: string | null;
  groupList: GroupWithEmailDomains[] | undefined;
  destinationAppUrl: string | undefined | null;
}

export const profileState: ProfileState = {
  apiState: {
    getMe: defaultApiState,
    updateMe: defaultApiState,
    updateMyPassword: defaultApiState,
    updateUserProfileImage: defaultApiState,
    getProfileImageSignedUrlState: defaultApiState,
    getGroupList: defaultApiState,
    getDestinationAppUrl: defaultApiState,
    removeDestinationApp: defaultApiState
  },
  profile: null,
  password: null,
  groupList: undefined,
  destinationAppUrl: undefined
};

export interface GroupWithEmailDomains {
  group: MyjbiGroupDetail;
  emailDomainArray: string[];
}
