import { ApiState, defaultApiState } from '@/store/types/general.types';

export interface RbacState {
  apiState: {
    getMyRoles: ApiState;
    getUserPermissionsMatrix: ApiState;
  };
  roles: string[] | null;
  userPermissionsMatrix: UserPermissionsMatrix | null;
  groupsUserHasAccessTo: number[] | null;
  groupTypesUserHasAccessTo: string[] | null;
}

export const rbacState: RbacState = {
  apiState: {
    getMyRoles: defaultApiState,
    getUserPermissionsMatrix: defaultApiState
  },
  roles: null,
  userPermissionsMatrix: null,
  groupsUserHasAccessTo: null,
  groupTypesUserHasAccessTo: null
};

export interface UserPermissionsMatrix {
  create: string[];
  read: string[];
  update: string[];
  delete: string[];
}
