import { AxiosError } from 'axios';
import { get as _get, once } from 'lodash';
import { parse, stringify } from 'qs';
import { Plugin } from 'vuex';
import { RootState } from '../store';

export const redirectToLoginSignup = once(() => {
  const query = parse(window.location.search);
  const { email } = query;
  const loginQuery = stringify({
    destination: window.location.href,
    email
  });
  const loginUrl = `/login?${loginQuery}`;
  window.location.href = `${
    process.env.VUE_APP_BACKEND_HOSTNAME
  }/logout?destination=${encodeURIComponent(loginUrl)}`;
});

export const redirectToAccessForbidden = once(() => {
  window.location.replace('/access-forbidden');
});

function hasCookie(cookieName: string): boolean {
  return document.cookie
    .split(';')
    .some((item) => item.trim().startsWith(cookieName + '='));
}

export const forbiddenResponseHandlerPlugin: Plugin<RootState> = (store) => {
  const cookiePresent = hasCookie('jaas.sid');
  // If cookie is unset or has been manually deleted
  if (!cookiePresent) {
    window.location.replace(`${process.env.VUE_APP_BACKEND_HOSTNAME}/logout`);
  }

  store.subscribe((mutation) => {
    const { type } = mutation;
    if (type.endsWith('_ERROR')) {
      const error: AxiosError = mutation.payload;
      const status = _get(error, 'response.status') as number;
      const errorMessage = error.response?.data.message;
      const isForbidden = status === 403;
      const isSessionInvalid = status === 401;
      if (isForbidden && cookiePresent) {
        localStorage.setItem('forbiddenResourceErrorMessage', errorMessage);
        redirectToAccessForbidden();
      }

      if (isSessionInvalid) {
        redirectToLoginSignup();
      }
    }
  });
};
