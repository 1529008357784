import { authState } from './auth.state';
import { authGetters } from './auth.getters';
import { authActions } from './auth.actions';
import { authMutations } from './auth.mutations';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: authState,
  getters: authGetters,
  actions: authActions,
  mutations: authMutations,
} as ModuleTree<RootState>;
