import { Store } from 'vuex';
import { orderApi } from '@/api/order.api';
import { OrderState, CreateOrderPayload } from './order.types';

export const orderActions = {
  async getMyOrders(store: Store<OrderState>) {
    try {
      store.commit('GET_ORDERS_LOADING');

      const response = await orderApi.getMyOrders();

      store.commit('GET_ORDERS_SUCCESS', response.data);
    } catch (error) {
      store.commit('GET_ORDERS_ERROR', error);
    }
  },
  async getOrder(store: Store<OrderState>, id: number) {
    try {
      store.commit('GET_ORDER_LOADING');

      const response = await orderApi.getOrder(id);

      store.commit('GET_ORDER_SUCCESS', response.data);
    } catch (error) {
      store.commit('GET_ORDER_ERROR', error);
    }
  },
  async createOrder(store: Store<OrderState>, payload: CreateOrderPayload) {
    try {
      store.commit('CREATE_ORDER_LOADING');

      const response = await orderApi.createOrder(payload);

      store.commit('CREATE_ORDER_SUCCESS');

      return response;
    } catch (error) {
      store.commit('CREATE_ORDER_ERROR', error);
    }
  },
  async updateOrder(
    store: Store<OrderState>,
    { id, resultKey }: { id: number; resultKey: string },
  ) {
    try {
      store.commit('UPDATE_ORDER_LOADING');

      await orderApi.updateOrder(id, resultKey);

      store.commit('UPDATE_ORDER_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_ORDER_ERROR', error);
    }
  },
  async orderReset(store: Store<OrderState>) {
    store.commit('ORDER_RESET');
  },
  async retryPayment(store: Store<OrderState>, paymentId: number) {
    try {
      store.commit('RETRY_PAYMENT_LOADING');

      const response = await orderApi.retryPayment(paymentId);

      store.commit('RETRY_PAYMENT_SUCCESS');

      return response.data;
    } catch (error) {
      store.commit('RETRY_PAYMENT_ERROR', error);
    }
  },
};
