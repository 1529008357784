import { GetJournalsRequestPayload } from '../store/modules/journal';
import { axios } from './axios';

export const journalApi = {
  getJournals(params: GetJournalsRequestPayload) {
    return axios.get('/journals', {
      params
    });
  }
};
