import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';
import { rbacState, rbacGetters, rbacActions, rbacMutations } from '.';

export default {
  namespaced: true,
  state: rbacState,
  getters: rbacGetters,
  actions: rbacActions,
  mutations: rbacMutations,
} as ModuleTree<RootState>;
