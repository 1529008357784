export const moneyParser = ({
  currency,
  value,
  fraction = 2,
}: {
  currency?: string;
  value: number;
  fraction?: number;
}) => {
  const price = (value / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  });
  if (currency) {
    return `${currency} ${price}`;
  }
  return price;
};
