import { JAAS_APP } from '@/jbi-shared/types/jaas-app.types';
import {
  GroupSubscriptionStatus,
  SubscriptionStatus
} from '@/jbi-shared/types/jaas-oauth2.types';
import { ApiState, defaultApiState } from '@/store/types/general.types';
import { Application, Product } from '../application/types/application.types';
import { Profile } from '../profile/profile.state';

export interface UserSubscription {
  id: number;
  application: Application;
  applicationId: JAAS_APP;
  applicationProduct: Product;
  applicationProductId: number;
  endedAt: string;
  startedAt: string;
  subscriptionStatus?: SubscriptionStatus;
  groupSubscriptionStatus?: GroupSubscriptionStatus;
}

export interface ExtendProductSubscriptionPayload {
  applicationId: string;
  productId: number;
  exclusionDuration: number;
  exclusionDurationType: string;
}

export interface Client {
  id: number;
  clientIdentifier: string;
  clientSecret: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: number;
}

export interface User {
  id: number;
  username: string;
  passwordHash: string;
  email: string;
  emailVerified: boolean;
  blocked: boolean;
  createdBy: null;
  updatedBy: null;
  createdAt: string;
  updatedAt: string;
  userProfile?: Profile;
}

export interface SubscriptionState {
  apiState: {
    getMySubscriptions: ApiState;
    extendProductSubscription: ApiState;
    updateMe: ApiState;
  };
  subscriptions: UserSubscription[] | null;
  extendSubscription: boolean | null;
}

export const subscriptionState: SubscriptionState = {
  apiState: {
    getMySubscriptions: defaultApiState,
    extendProductSubscription: defaultApiState,
    updateMe: defaultApiState
  },
  subscriptions: null,
  extendSubscription: null
};
