import { defaultApiState } from '../../types/general.types';
import { GroupWithEmailDomains, Profile, ProfileState } from './profile.state';
import {
  GetSignedUrlForUploadResponsePayload,
  ManagePasswordPayload
} from './types/profile.types';

export const profileMutations = {
  /*
   ** getMe
   */
  GET_ME_LOADING(state: ProfileState) {
    state.apiState.getMe = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ME_SUCCESS(state: ProfileState, payload: Profile) {
    state.apiState.getMe = {
      ...defaultApiState,
      success: true
    };
    state.profile = payload;
  },
  GET_ME_ERROR(state: ProfileState, error: Error) {
    state.apiState.getMe = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** updateMe
   */
  UPDATE_ME_LOADING(state: ProfileState) {
    state.apiState.updateMe = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_ME_SUCCESS(state: ProfileState, payload: Profile) {
    state.apiState.updateMe = {
      ...defaultApiState,
      success: true
    };
    state.profile = { ...state.profile, ...payload };
  },
  UPDATE_ME_ERROR(state: ProfileState, error: Error) {
    state.apiState.updateMe = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** updateMyPassword
   */
  UPDATE_PASSWORD_LOADING(state: ProfileState) {
    state.apiState.updateMyPassword = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_PASSWORD_SUCCESS(state: ProfileState, payload: ManagePasswordPayload) {
    state.apiState.updateMyPassword = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_PASSWORD_ERROR(state: ProfileState, error: Error) {
    state.apiState.updateMyPassword = {
      ...defaultApiState,
      error
    };
  },
  UPDATE_PROFILE_IMAGE_LOADING(state: ProfileState) {
    state.apiState.updateUserProfileImage = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_PROFILE_IMAGE_SUCCESS(state: ProfileState) {
    state.apiState.updateUserProfileImage = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_PROFILE_IMAGE_ERROR(state: ProfileState, error: Error) {
    state.apiState.updateUserProfileImage = {
      ...defaultApiState,
      error
    };
  },
  GET_SIGNED_URL_PROFILE_IMAGE_LOADING(state: ProfileState) {
    state.apiState.getProfileImageSignedUrlState = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_SIGNED_URL_PROFILE_IMAGE_SUCCESS(
    state: ProfileState,
    payload: GetSignedUrlForUploadResponsePayload
  ) {
    state.apiState.getProfileImageSignedUrlState = {
      ...defaultApiState,
      success: true
    };
  },
  GET_SIGNED_URL_PROFILE_IMAGE_ERROR(state: ProfileState, error: Error) {
    state.apiState.getProfileImageSignedUrlState = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** get Group List With Email Domain
   */
  GET_GROUP_LIST_LOADING(state: ProfileState) {
    state.apiState.getGroupList = {
      ...defaultApiState,
      loading: true
    };
    state.groupList = undefined;
  },
  GET_GROUP_LIST_SUCCESS(
    state: ProfileState,
    payload: GroupWithEmailDomains[]
  ) {
    state.apiState.getGroupList = {
      ...defaultApiState,
      success: true
    };
    state.groupList = payload;
  },
  GET_GROUP_LIST_ERROR(state: ProfileState, error: Error) {
    state.apiState.getGroupList = {
      ...defaultApiState,
      error
    };
    state.groupList = undefined;
  },

  GET_DESTINATION_APP_LOADING(state: ProfileState): void {
    state.apiState.getDestinationAppUrl = {
      ...defaultApiState,
      loading: true
    };
    state.destinationAppUrl = undefined;
  },
  GET_DESTINATION_APP_SUCCESS(state: ProfileState, payload: string): void {
    state.apiState.getDestinationAppUrl = {
      ...defaultApiState,
      success: true
    };
    state.destinationAppUrl = payload;
  },
  GET_DESTINATION_APP_ERROR(state: ProfileState, error: Error): void {
    state.apiState.getDestinationAppUrl = {
      ...defaultApiState,
      error
    };
    state.destinationAppUrl = undefined;
  },

  REMOVE_DESTINATION_APP_LOADING(state: ProfileState): void {
    state.apiState.getDestinationAppUrl = {
      ...defaultApiState,
      loading: true
    };
  },
  REMOVE_DESTINATION_APP_SUCCESS(state: ProfileState): void {
    state.apiState.getDestinationAppUrl = {
      ...defaultApiState,
      success: true
    };
  },
  REMOVE_DESTINATION_APP_ERROR(state: ProfileState, error: Error): void {
    state.apiState.getDestinationAppUrl = {
      ...defaultApiState,
      error
    };
  }
};
