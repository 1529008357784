import { RootState } from '@/store/store';
import { ModuleTree } from 'vuex';
import { adminActions } from './admin.actions';
import { adminMutations } from './admin.mutations';
import { adminState } from './admin.state';

export default {
  namespaced: true,
  state: adminState,
  actions: adminActions,
  mutations: adminMutations,
} as ModuleTree<RootState>;
