import { ThirdPartyUserCredentials } from '@/views/AdminThirdPartyManagement/types/admin-third-party-management.types';
import { ToastProgrammatic as Toast } from 'buefy';
import dayjs from 'dayjs';
import XLSX from 'xlsx';

export class ThirdPartyUserExportToCsv {
  public async create(data: ThirdPartyUserCredentials) {
    let exportedWorkbook;
    let exportedWorkSheet;
    const exportDateTime = dayjs().format('D MMM YYYY');
    const exportTitle = data.username + '_' + exportDateTime;
    exportedWorkbook = XLSX.utils.book_new();
    exportedWorkSheet = XLSX.utils.json_to_sheet(
      [
        {
          column1: 'Username',
          column2: data.username
        },
        {
          column1: 'Password',
          column2: data.secret
        }
      ],
      {
        skipHeader: true
      }
    );
    XLSX.utils.book_append_sheet(
      exportedWorkbook,
      exportedWorkSheet,
      data.username
    );
    try {
      XLSX.writeFile(exportedWorkbook, exportTitle + '.csv');
    } catch (err) {
      Toast.open({
        message: `Something went wrong. Try again.`,
        position: 'is-top',
        type: 'is-danger',
        duration: 3500
      });
    } finally {
      Toast.open({
        message: `Export complete.`,
        position: 'is-top',
        type: 'is-dark',
        duration: 3500
      });
    }
  }
}
