import { subscriptionState } from './subscription.state';
import { subscriptionGetters } from './subscription.getters';
import { subscriptionActions } from './subscription.actions';
import { subscriptionMutations } from './subscription.mutations';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: subscriptionState,
  getters: subscriptionGetters,
  actions: subscriptionActions,
  mutations: subscriptionMutations,
} as ModuleTree<RootState>;
