import { defaultApiState } from '@/store/types/general.types';
import { AuditLogState } from './types/audit-log.types';

export const auditLogState: AuditLogState = {
  apiState: {
    getAuditLogs: defaultApiState,
    exportAuditLogsCsv: defaultApiState,
    exportAuditLogsPdf: defaultApiState,
  },
  auditLogs: undefined,
  pdfAuditLogs: undefined,
};
