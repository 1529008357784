import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

import { orderState, orderGetters, orderActions, orderMutations } from './';

export default {
  namespaced: true,
  state: orderState,
  getters: orderGetters,
  actions: orderActions,
  mutations: orderMutations,
} as ModuleTree<RootState>;
