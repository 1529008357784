// TODO: Temporary, Change resource to something JAAS will be using
export enum JaasResource {
  Products = 'products',
}

export enum JaasAction {
  GET = 'read',
  POST = 'create',
  PUT = 'update',
  DELETE = 'delete',
}

export type JaasRoles = GeneralRoles;

export enum GeneralRoles {
  Admin = 'super-admin-role',
  User = 'default-user-role',
}

export interface RoleGuardMetadata {
  role: JaasRoles;
  param?: string;
}

export enum EntityTypes {
  PRODUCT = 'product',
  GROUP = 'group',
  THIRD_PARTY_USER = 'tpu',
}
