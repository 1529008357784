import {
  defaultApiState,
  PaginatedResponse,
} from '@/store/types/general.types';
import { JournalState } from '.';
import { Journal } from './journal.types';

export const journalMutations = {
  GET_JOURNALS_LOADING(state: JournalState) {
    state.apiState.getJournals = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_JOURNALS_SUCCESS(
    state: JournalState,
    payload: PaginatedResponse<Journal>,
  ) {
    state.apiState.getJournals = {
      ...defaultApiState,
      success: true,
    };
    state.journals = payload;
  },
  GET_JOURNALS_ERROR(state: JournalState, error: Error) {
    state.apiState.getJournals = {
      ...defaultApiState,
      error,
    };
  },
  GET_JOURNALS_RESET(state: JournalState) {
    state.journals = undefined;
  },
};
