import { GeneralRoles } from '@/jbi-shared/types/jaas-permission.types';
import { RbacState } from './rbac.state';

export const rbacGetters = {
  isAdmin: (state: RbacState) => {
    if (!state.roles) {
      return false;
    }
    return state.roles.includes(GeneralRoles.Admin);
  },
  rootModules: (state: RbacState) => {
    if (state.userPermissionsMatrix && state.userPermissionsMatrix.read) {
      // Root modules don't have the '-' character
      return state.userPermissionsMatrix.read.filter(
        (module) => module && !module.includes('-')
      );
    }

    return {
      read: ['account']
    };
  },
  readModules: (state: RbacState) => {
    if (state.userPermissionsMatrix) {
      return state.userPermissionsMatrix.read;
    }

    return [];
  },
  userPermissionsMatrix: (state: RbacState) => {
    if (state.userPermissionsMatrix) {
      return state.userPermissionsMatrix;
    }
  }
};
