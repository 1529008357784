import { defaultApiState } from '@/store/types/general.types';
import { RbacState, UserPermissionsMatrix } from './rbac.state';

export const rbacMutations = {
  GET_MY_ROLES_LOADING(state: RbacState) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_MY_ROLES_SUCCESS(state: RbacState, payload: string[]) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      success: true
    };
    state.roles = payload;
  },
  GET_MY_ROLES_ERROR(state: RbacState, error: Error) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      error
    };
  },
  GET_USER_PERMISSIONS_MATRIX_LOADING(state: RbacState) {
    state.apiState.getUserPermissionsMatrix = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_USER_PERMISSIONS_MATRIX_SUCCESS(
    state: RbacState,
    payload: UserPermissionsMatrix
  ) {
    state.apiState.getUserPermissionsMatrix = {
      ...defaultApiState,
      success: true
    };
    state.userPermissionsMatrix = payload;
  },
  GET_USER_PERMISSIONS_MATRIX_ERROR(state: RbacState, error: Error) {
    state.apiState.getUserPermissionsMatrix = {
      ...defaultApiState,
      error
    };
  },
  SET_GROUPS_USER_HAS_ACCESS_TO(state: RbacState, groupIds: number[]) {
    state.groupsUserHasAccessTo = groupIds;
  },
  SET_GROUP_TYPES_USER_HAS_ACCESS_TO(state: RbacState, groupTypes: string[]) {
    state.groupTypesUserHasAccessTo = groupTypes;
  }
};
