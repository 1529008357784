import { ApplicationState } from '@/store/modules/application/types/application.types';
import { defaultApiState } from '@/store/types/general.types';
import { Product } from './types/application.types';

export const applicationMutations = {
  GET_APPLICATIONS_LOADING(state: ApplicationState) {
    state.apiState.getApplications = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_APPLICATIONS_SUCCESS(state: ApplicationState, payload: any) {
    state.apiState.getApplications = {
      ...defaultApiState,
      success: true
    };
    state.productApplications = payload;
  },
  GET_APPLICATIONS_ERROR(state: ApplicationState, error: Error) {
    state.apiState.getApplications = {
      ...defaultApiState,
      error
    };
  },
  GET_PRODUCT_LOADING(state: ApplicationState) {
    state.apiState.getProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_PRODUCT_SUCCESS(state: ApplicationState, payload: Product) {
    state.apiState.getProduct = {
      ...defaultApiState,
      success: true
    };
    state.product = payload;
  },
  GET_PRODUCT_ERROR(state: ApplicationState, error: Error) {
    state.apiState.getProduct = {
      ...defaultApiState,
      error
    };
  },
  GET_PRODUCTS_LOADING(state: ApplicationState) {
    state.apiState.getProducts = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_PRODUCTS_SUCCESS(state: ApplicationState, payload: Product[]) {
    state.apiState.getProducts = {
      ...defaultApiState,
      success: true
    };
    state.products = payload;
  },
  GET_PRODUCTS_ERROR(state: ApplicationState, error: Error) {
    state.apiState.getProducts = {
      ...defaultApiState,
      error
    };
  },
  GET_ADMINISTRATION_PRODUCTS_LOADING(state: ApplicationState) {
    state.apiState.getAdministrationProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ADMINISTRATION_PRODUCTS_SUCCESS(state: ApplicationState, payload: any) {
    state.apiState.getAdministrationProduct = {
      ...defaultApiState,
      success: true
    };
    state.administrationProducts = payload;
  },
  GET_ADMINISTRATION_PRODUCTS_ERROR(state: ApplicationState, error: Error) {
    state.apiState.getAdministrationProduct = {
      ...defaultApiState,
      error
    };
  },
  CREATE_PRODUCT_LOADING(state: ApplicationState) {
    state.apiState.createProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_PRODUCT_SUCCESS(state: ApplicationState) {
    state.apiState.createProduct = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_PRODUCT_ERROR(state: ApplicationState, error: Error) {
    state.apiState.createProduct = {
      ...defaultApiState,
      error
    };
  },
  UPDATE_PRODUCT_LOADING(state: ApplicationState) {
    state.apiState.updateProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_PRODUCT_SUCCESS(state: ApplicationState) {
    state.apiState.updateProduct = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_PRODUCT_ERROR(state: ApplicationState, error: Error) {
    state.apiState.updateProduct = {
      ...defaultApiState,
      error
    };
  },
  GET_LICENSE_MODELS_LOADING(state: ApplicationState) {
    state.apiState.getLicenseModels = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_LICENSE_MODELS_SUCCESS(state: ApplicationState, payload: any) {
    state.apiState.getLicenseModels = {
      ...defaultApiState,
      success: true
    };
    state.licenseModels = payload;
  },
  GET_LICENSE_MODELS_ERROR(state: ApplicationState, error: Error) {
    state.apiState.getLicenseModels = {
      ...defaultApiState,
      error
    };
  },
  DISABLE_PRODUCT_LOADING(state: ApplicationState) {
    state.apiState.disableProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  DISABLE_PRODUCT_SUCCESS(state: ApplicationState) {
    state.apiState.disableProduct = {
      ...defaultApiState,
      success: true
    };
  },
  DISABLE_PRODUCT_ERROR(state: ApplicationState, error: Error) {
    state.apiState.disableProduct = {
      ...defaultApiState,
      error
    };
  },
  DELETE_PRODUCT_LOADING(state: ApplicationState) {
    state.apiState.deletedProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_PRODUCT_SUCCESS(state: ApplicationState) {
    state.apiState.deletedProduct = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_PRODUCT_ERROR(state: ApplicationState, error: Error) {
    state.apiState.deletedProduct = {
      ...defaultApiState,
      error
    };
  },
  SET_CURRENT_PRODUCT(state: ApplicationState, payload: string) {
    state.currentProduct = payload;
  },
  SET_CURRENT_PRODUCT_STATUS(state: ApplicationState, payload: string) {
    state.currentProductStatus = payload;
  }
};
