import { RootState } from '@/store/store';
import { ModuleTree } from 'vuex';
import { auditLogActions } from './audit-log.actions';
import { auditLogGetters } from './audit-log.getters';
import { auditLogMutations } from './audit-log.mutations';
import { auditLogState } from './audit-log.state';

export default {
  namespaced: true,
  state: auditLogState,
  getters: auditLogGetters,
  actions: auditLogActions,
  mutations: auditLogMutations,
} as ModuleTree<RootState>;
