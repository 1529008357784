export enum EntityTypes {
  PRODUCT = 'product',
  GROUP = 'group',
  THIRD_PARTY_USER = 'tpu'
}

export enum ModuleActionTypes {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  ALL = 'all'
}
