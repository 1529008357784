import { roleActions } from '@/store/modules/role/role.actions';
import { roleMutations } from '@/store/modules/role/role.mutation';
import { roleState } from '@/store/modules/role/role.state';
import { RootState } from '@/store/store';
import { ModuleTree } from 'vuex';

export default {
  namespaced: true,
  state: roleState,
  actions: roleActions,
  mutations: roleMutations
} as ModuleTree<RootState>;
