import { defaultApiState } from '@/store/types/general.types';
import { SubscriptionState, UserSubscription } from './subscription.state';

export const subscriptionMutations = {
  /*
   ** getMySubscriptions
   */
  GET_MY_SUBSCRIPTION_LOADING(state: SubscriptionState) {
    state.apiState.getMySubscriptions = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_MY_SUBSCRIPTION_SUCCESS(
    state: SubscriptionState,
    payload: UserSubscription[],
  ) {
    state.apiState.getMySubscriptions = {
      ...defaultApiState,
      success: true,
    };
    state.subscriptions = payload;
  },
  GET_MY_SUBSCRIPTION_ERROR(state: SubscriptionState, error: Error) {
    state.apiState.getMySubscriptions = {
      ...defaultApiState,
      error,
    };
  },
  EXTEND_MY_SUBSCRIPTION_LOADING(state: SubscriptionState) {
    state.apiState.extendProductSubscription = {
      ...defaultApiState,
      loading: true,
    };
  },
  EXTEND_MY_SUBSCRIPTION_SUCCESS(state: SubscriptionState, payload: boolean) {
    state.apiState.extendProductSubscription = {
      ...defaultApiState,
      success: true,
    };
    state.extendSubscription = payload;
  },
  EXTEND_MY_SUBSCRIPTION_ERROR(state: SubscriptionState, error: Error) {
    state.apiState.extendProductSubscription = {
      ...defaultApiState,
      error,
    };
  },
};
