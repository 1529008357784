import {
  DeleteProductValues,
  DisableProductValues,
  ProductFormValues
} from '@/store/modules/application/types/application.types';
import {
  GetAdministrativeProductsQuery,
  GetApplicationsPayload,
  GetProductsPayload
} from '../store/modules/application/types/application.types';
import { axios } from './axios';

export const applicationApi = {
  async getApplications(payload: GetApplicationsPayload) {
    return axios.get('/applications', {
      params: payload
    });
  },
  async getLicenseModels() {
    return axios.get('/applications/sumari/license-models');
  },
  async getOneProduct(params: { productId: number }) {
    const { productId } = params;
    return axios.get(`/products/${productId}`);
  },
  async getProducts(payload: GetProductsPayload) {
    return axios.get(`/applications/products`, {
      params: payload
    });
  },
  async getAdministrationProducts({
    limit,
    page,
    sortColumn,
    sortOrder,
    productName,
    status
  }: GetAdministrativeProductsQuery) {
    const params = new URLSearchParams();

    if (limit) {
      params.append('limit', limit + '');
    }
    if (page) {
      params.append('page', page + '');
    }
    if (sortColumn) {
      params.append('sortColumn', sortColumn + '');
    }
    if (sortOrder) {
      params.append('sortOrder', sortOrder + '');
    }
    if (productName) {
      params.append('productName', productName);
    }
    if (status) {
      params.append('status', status);
    }
    return axios.get(`admin/applications/products`, {
      params
    });
  },
  async createProduct(payload: ProductFormValues) {
    const { appId, ...rest } = payload;
    return axios.post(`admin/applications/${appId}/products`, rest);
  },
  async updateProduct(payload: ProductFormValues) {
    const { id, ...rest } = payload;

    return axios.put(`/products/${id}`, rest);
  },
  async disableProduct(payload: DisableProductValues) {
    const { id, ...rest } = payload;
    return axios.patch(`/products/${id}`, rest);
  },
  async deleteProduct(payload: DeleteProductValues) {
    const { id, ...rest } = payload;
    return axios.patch(`/products/delete/${id}`, rest);
  }
};
