import { ProfileUpdatePayload } from '@/store/modules/profile/profile.state';
import { AxiosPromise } from 'axios';
import { ManagePasswordPayload } from '../store/modules/profile/types/profile.types';
import { axios } from './axios';

export const profileApi = {
  async getMe() {
    return await axios.get('/me');
  },
  async updateMe(payload: ProfileUpdatePayload) {
    return axios.put('/me', {
      ...payload
    });
  },
  async updateMyPassword(passwordPayload: ManagePasswordPayload) {
    return axios.put('/me/password', {
      ...passwordPayload
    });
  },
  async getProfileImageSignedUrl(image: File) {
    return axios.post('/me/profile/image', image, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  async updateUserProfileImage(image: string) {
    return axios.post('/me/profile/update-profile-image', { url: image });
  },

  async getGroupList() {
    return axios.get('/me/group-list');
  },

  getDestinationAppUrl(): AxiosPromise<string> {
    return axios.get<string>('/me/destination-app');
  },

  removeDestinationAppUrl(): AxiosPromise<{ id: number }> {
    return axios.patch<{ id: number }>('/me/destination-app/nullify');
  }
};
