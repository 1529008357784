import { Store } from 'vuex';
import { journalApi } from '@/api/journal.api';
import { JournalState } from './';
import { GetJournalsRequestPayload } from './journal.types';

export const journalActions = {
  async getJournals(
    store: Store<JournalState>,
    payload: GetJournalsRequestPayload,
  ) {
    try {
      store.commit('GET_JOURNALS_LOADING');

      const response = await journalApi.getJournals(payload);

      store.commit('GET_JOURNALS_SUCCESS', response.data);
    } catch (error) {
      store.commit('GET_JOURNALS_ERROR', error);
    }
  },
  async resetJournals(store: Store<JournalState>) {
    store.commit('GET_JOURNALS_RESET');
  },
};
