import { auditLogApi } from '@/api/auditLog.api';
import { Store } from 'vuex';
import {
  AuditLogState,
  ExportFileAuditLogPayload,
  GetAuditLogPayload,
} from './types/audit-log.types';

export const auditLogActions = {
  async getAuditLog(store: Store<AuditLogState>, params: GetAuditLogPayload) {
    try {
      store.commit('GET_AUDIT_LOG_LOADING');

      const result = await auditLogApi.getAuditLogs(params);

      store.commit('GET_AUDIT_LOG_SUCCESS', result.data);
    } catch (error) {
      store.commit('GET_AUDIT_LOG_ERROR', error);
    }
  },
  async exportPdfAuditLog(
    store: Store<AuditLogState>,
    params: GetAuditLogPayload,
  ) {
    try {
      store.commit('EXPORT_PDF_AUDIT_LOG_LOADING');

      const result = await auditLogApi.exportAuditLogsPdf(params);

      store.commit('EXPORT_PDF_AUDIT_LOG_SUCCESS', result.data);
    } catch (error) {
      store.commit('EXPORT_PDF_AUDIT_LOG_ERROR', error);
    }
  },
  async exportCsvAuditLog(
    store: Store<AuditLogState>,
    params: ExportFileAuditLogPayload,
  ) {
    try {
      store.commit('EXPORT_CSV_AUDIT_LOG_LOADING');

      const result = await auditLogApi.exportAuditLogsCsv(params);

      const htmlObject = document.createElement('a');
      htmlObject.innerHTML = result.data;

      const res = htmlObject.firstChild;
      // @ts-ignore
      res.click();
      store.commit('EXPORT_CSV_AUDIT_LOG_SUCCESS');
    } catch (error) {
      store.commit('EXPORT_CSV_AUDIT_LOG_ERROR', error);
    }
  },
};
