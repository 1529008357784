import { moduleTreeApi } from '@/api/module-tree.api';
import { ModuleTreeState } from '@/store/modules/module-tree/types/module-tree.types';
import { Store } from 'vuex';

export const moduleTreeActions: {
  getModuleTree(store: Store<ModuleTreeState>): Promise<void>;
} = {
  async getModuleTree(store: Store<ModuleTreeState>): Promise<void> {
    try {
      store.commit('GET_MODULE_TREE_LOADING');
      const { data } = await moduleTreeApi.getModuleTree();
      store.commit('GET_MODULE_TREE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_MODULE_TREE_ERROR', error);
    }
  }
};
