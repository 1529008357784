import { ApiState, defaultApiState } from '@/store/types/general.types';
import { SumariState } from '@/store/modules/sumari/sumari.types';

export const sumariState: SumariState = {
  apiState: {
    getWkSubscription: defaultApiState,
    covertToMyJbiUser: defaultApiState,
  },
  wkSubscription: {},
};
