import { defaultApiState } from '@/store/types/general.types';
import { OrderState, Order } from './order.types';

export const orderMutations = {
  GET_ORDERS_LOADING(state: OrderState) {
    state.apiState.getMyOrders = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_ORDERS_SUCCESS(state: OrderState, data: any[]) {
    state.apiState.getMyOrders = {
      ...defaultApiState,
      success: true,
    };
    state.orders = data;
  },
  GET_ORDERS_ERROR(state: OrderState, error: Error) {
    state.apiState.getMyOrders = {
      ...defaultApiState,
      error,
    };
  },
  GET_ORDER_LOADING(state: OrderState) {
    state.apiState.getOrder = {
      ...defaultApiState,
      loading: true,
    };
  },
  GET_ORDER_SUCCESS(state: OrderState, payload: any) {
    state.apiState.getOrder = {
      ...defaultApiState,
      success: true,
    };
    state.order = payload;
  },
  GET_ORDER_ERROR(state: OrderState, error: Error) {
    state.apiState.getOrder = {
      ...defaultApiState,
      error,
    };
  },
  UPDATE_ORDER_LOADING(state: OrderState) {
    state.apiState.updateOrder = {
      ...defaultApiState,
      loading: true,
    };
  },
  UPDATE_ORDER_SUCCESS(state: OrderState) {
    state.apiState.updateOrder = {
      ...defaultApiState,
      success: true,
    };
  },
  UPDATE_ORDER_ERROR(state: OrderState, error: Error) {
    state.apiState.getOrder = {
      ...defaultApiState,
      error,
    };
  },
  CREATE_ORDER_LOADING(state: OrderState) {
    state.apiState.createOrder = {
      ...defaultApiState,
      loading: true,
    };
  },
  CREATE_ORDER_SUCCESS(state: OrderState) {
    state.apiState.createOrder = {
      ...defaultApiState,
      success: true,
    };
  },
  CREATE_ORDER_ERROR(state: OrderState, error: Error) {
    state.apiState.createOrder = {
      ...defaultApiState,
      error,
    };
  },
  ORDER_RESET(state: OrderState) {
    state.order = undefined;
  },
  RETRY_PAYMENT_LOADING(state: OrderState) {
    state.apiState.retryPayment = {
      ...defaultApiState,
      loading: true,
    };
  },
  RETRY_PAYMENT_SUCCESS(state: OrderState) {
    state.apiState.retryPayment = {
      ...defaultApiState,
      success: true,
    };
  },

  RETRY_PAYMENT_ERROR(state: OrderState, error: Error) {
    state.apiState.retryPayment = {
      ...defaultApiState,
      error,
    };
  },
};
