import { profileApi } from '@/api/profile.api';
import { Store } from 'vuex';
import { ProfileState, ProfileUpdatePayload } from './profile.state';
import { ManagePasswordPayload } from './types/profile.types';

export const profileActions = {
  async getMe(store: Store<ProfileState>) {
    try {
      store.commit('GET_ME_LOADING');

      const { data } = await profileApi.getMe();
      store.commit('GET_ME_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ME_ERROR', error);
    }
  },
  // Get Group List along with Email domains
  async getGroupList(store: Store<ProfileState>) {
    try {
      store.commit('GET_GROUP_LIST_LOADING');

      const { data } = await profileApi.getGroupList();
      store.commit('GET_GROUP_LIST_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_LIST_ERROR', error);
    }
  },
  async updateMe(store: Store<ProfileState>, payload: ProfileUpdatePayload) {
    try {
      store.commit('UPDATE_ME_LOADING');

      const { data } = await profileApi.updateMe(payload);
      store.commit('UPDATE_ME_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_ME_ERROR', error);
    }
  },
  async updateMyPassword(
    store: Store<ProfileState>,
    payload: ManagePasswordPayload
  ) {
    try {
      store.commit('UPDATE_PASSWORD_LOADING');

      const { data } = await profileApi.updateMyPassword(payload);
      store.commit('UPDATE_PASSWORD_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PASSWORD_ERROR', error);
    }
  },
  async getProfileImageSignedUrl(store: Store<ProfileState>, payload: File) {
    try {
      store.commit('GET_SIGNED_URL_PROFILE_IMAGE_LOADING');

      const { data } = await profileApi.getProfileImageSignedUrl(payload);
      store.commit('GET_SIGNED_URL_PROFILE_IMAGE_SUCCESS', data);
      return data;
    } catch (error) {
      store.commit('GET_SIGNED_URL_PROFILE_IMAGE_ERROR', error);
    }
  },

  async updateUserProfileImage(store: Store<ProfileState>, payload: string) {
    try {
      store.commit('UPDATE_PROFILE_IMAGE_LOADING');

      const { data } = await profileApi.updateUserProfileImage(payload);
      store.commit('UPDATE_PROFILE_IMAGE_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROFILE_IMAGE_ERROR', error);
    }
  },

  async getDestinationAppUrl(store: Store<ProfileState>): Promise<void> {
    try {
      store.commit('GET_DESTINATION_APP_LOADING');

      const { data } = await profileApi.getDestinationAppUrl();
      store.commit('GET_DESTINATION_APP_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DESTINATION_APP_ERROR', error);
    }
  },

  async removeDestinationAppUrl(store: Store<ProfileState>): Promise<void> {
    try {
      store.commit('REMOVE_DESTINATION_APP_LOADING');

      const { data } = await profileApi.removeDestinationAppUrl();
      store.commit('REMOVE_DESTINATION_APP_SUCCESS', data);
    } catch (error) {
      store.commit('REMOVE_DESTINATION_APP_ERROR', error);
    }
  }
};
