import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';
import { sumariState } from '@/store/modules/sumari/sumari.state';
import { sumariActions } from '@/store/modules/sumari/sumari.actions';
import { sumariMutations } from '@/store/modules/sumari/sumari.mutations';

export default {
  namespaced: true,
  state: sumariState,
  actions: sumariActions,
  mutations: sumariMutations,
} as ModuleTree<RootState>;
