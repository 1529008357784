import { RootState } from '@/store/store';
import { ModuleTree } from 'vuex';
import { userAttributeActions } from './user-attribute.actions';
import { userAttributeGetters } from './user-attribute.getters';
import { userAttributeMutations } from './user-attribute.mutations';
import { userAttributeState } from './user-attribute.state';

export default {
  namespaced: true,
  state: userAttributeState,
  getters: userAttributeGetters,
  actions: userAttributeActions,
  mutations: userAttributeMutations
} as ModuleTree<RootState>;
