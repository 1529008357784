import { RoleState } from '@/store/modules/role/types/role.types';
import { defaultApiState } from '@/store/types/general.types';

export const roleState: RoleState = {
  apiState: {
    getRoles: defaultApiState,
    getRoleById: defaultApiState,
    createRole: defaultApiState,
    updateRole: defaultApiState,
    deleteRole: defaultApiState,
    getUsersAssignedToRole: defaultApiState,
    getGroupsAssignedToRole: defaultApiState,
    deleteUsersFromRole: defaultApiState,
    deleteGroupsFromRole: defaultApiState,
    deleteInvitationsFromRole: defaultApiState
  },
  roles: undefined,
  role: undefined,
  roleWithUsers: undefined,
  roleWithGroups: undefined
};
