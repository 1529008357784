import {
  CreateRolePayload,
  RoleSansTree,
  RoleState,
  RoleWithGroups,
  RoleWithTree,
  RoleWithUsers,
  UpdateRolePayload
} from '@/store/modules/role/types/role.types';
import {
  defaultApiState,
  PaginatedResponse
} from '@/store/types/general.types';
import { AxiosError } from 'axios';

export const roleMutations: {
  GET_ROLES_LOADING(state: RoleState): void;
  GET_ROLES_SUCCESS(
    state: RoleState,
    data: PaginatedResponse<RoleSansTree>
  ): void;
  GET_ROLES_ERROR(state: RoleState, error: AxiosError): void;
  GET_ROLE_BY_ID_LOADING(state: RoleState): void;
  GET_ROLE_BY_ID_SUCCESS(state: RoleState, data: RoleWithTree): void;
  GET_ROLE_BY_ID_ERROR(state: RoleState, error: AxiosError): void;
  CREATE_ROLE_LOADING(state: RoleState): void;
  CREATE_ROLE_SUCCESS(state: RoleState, payload: CreateRolePayload): void;
  CREATE_ROLE_ERROR(state: RoleState, error: AxiosError): void;
  UPDATE_ROLE_LOADING(state: RoleState): void;
  UPDATE_ROLE_SUCCESS(
    state: RoleState,
    id: number,
    payload: UpdateRolePayload
  ): void;
  UPDATE_ROLE_ERROR(state: RoleState, error: AxiosError): void;
  DELETE_ROLE_LOADING(state: RoleState): void;
  DELETE_ROLE_SUCCESS(state: RoleState, id: number): void;
  DELETE_ROLE_ERROR(state: RoleState, error: AxiosError): void;
  GET_USERS_ASSIGNED_TO_ROLE_LOADING(state: RoleState): void;
  GET_USERS_ASSIGNED_TO_ROLE_SUCCESS(
    state: RoleState,
    data: RoleWithUsers
  ): void;
  GET_USERS_ASSIGNED_TO_ROLE_ERROR(state: RoleState, error: AxiosError): void;
  GET_GROUPS_ASSIGNED_TO_ROLE_LOADING(state: RoleState): void;
  GET_GROUPS_ASSIGNED_TO_ROLE_SUCCESS(
    state: RoleState,
    data: RoleWithGroups
  ): void;
  GET_GROUPS_ASSIGNED_TO_ROLE_ERROR(state: RoleState, error: AxiosError): void;
  DELETE_USERS_FROM_ROLE_LOADING(state: RoleState): void;
  DELETE_USERS_FROM_ROLE_SUCCESS(state: RoleState, id: number): void;
  DELETE_USERS_FROM_ROLE_ERROR(state: RoleState, error: AxiosError): void;
  DELETE_GROUPS_FROM_ROLE_LOADING(state: RoleState): void;
  DELETE_GROUPS_FROM_ROLE_SUCCESS(state: RoleState, id: number): void;
  DELETE_GROUPS_FROM_ROLE_ERROR(state: RoleState, error: AxiosError): void;
  DELETE_INVITATIONS_FROM_ROLE_LOADING(state: RoleState): void;
  DELETE_INVITATIONS_FROM_ROLE_SUCCESS(state: RoleState, id: number): void;
  DELETE_INVITATIONS_FROM_ROLE_ERROR(state: RoleState, error: AxiosError): void;
} = {
  // Get Roles
  GET_ROLES_LOADING(state: RoleState) {
    state.apiState.getRoles = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ROLES_SUCCESS(state: RoleState, data: PaginatedResponse<RoleSansTree>) {
    state.apiState.getRoles = {
      ...defaultApiState,
      success: true
    };
    state.roles = data;
  },
  GET_ROLES_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.getRoles = {
      ...defaultApiState,
      error
    };
  },
  // Get Role By ID
  GET_ROLE_BY_ID_LOADING(state: RoleState) {
    state.apiState.getRoleById = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ROLE_BY_ID_SUCCESS(state: RoleState, data: RoleWithTree) {
    state.apiState.getRoleById = {
      ...defaultApiState,
      success: true
    };
    state.role = data;
  },
  GET_ROLE_BY_ID_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.getRoleById = {
      ...defaultApiState,
      error
    };
  },
  // Create Role
  CREATE_ROLE_LOADING(state: RoleState) {
    state.apiState.createRole = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_ROLE_SUCCESS(state: RoleState) {
    state.apiState.createRole = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_ROLE_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.createRole = {
      ...defaultApiState,
      error
    };
  },
  // Update Role
  UPDATE_ROLE_LOADING(state: RoleState) {
    state.apiState.updateRole = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_ROLE_SUCCESS(state: RoleState) {
    state.apiState.updateRole = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_ROLE_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.updateRole = {
      ...defaultApiState,
      error
    };
  },
  // Delete Role
  DELETE_ROLE_LOADING(state: RoleState) {
    state.apiState.deleteRole = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_ROLE_SUCCESS(state: RoleState) {
    state.apiState.deleteRole = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_ROLE_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.deleteRole = {
      ...defaultApiState,
      error
    };
  },
  // Get users assigned to role
  GET_USERS_ASSIGNED_TO_ROLE_LOADING(state: RoleState) {
    state.apiState.getUsersAssignedToRole = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_USERS_ASSIGNED_TO_ROLE_SUCCESS(state: RoleState, data: RoleWithUsers) {
    state.apiState.getUsersAssignedToRole = {
      ...defaultApiState,
      success: true
    };
    state.roleWithUsers = data;
  },
  GET_USERS_ASSIGNED_TO_ROLE_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.getUsersAssignedToRole = {
      ...defaultApiState,
      error
    };
  },
  // Get users assigned to role
  GET_GROUPS_ASSIGNED_TO_ROLE_LOADING(state: RoleState) {
    state.apiState.getGroupsAssignedToRole = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_GROUPS_ASSIGNED_TO_ROLE_SUCCESS(state: RoleState, data: RoleWithGroups) {
    state.apiState.getGroupsAssignedToRole = {
      ...defaultApiState,
      success: true
    };
    state.roleWithGroups = data;
  },
  GET_GROUPS_ASSIGNED_TO_ROLE_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.getGroupsAssignedToRole = {
      ...defaultApiState,
      error
    };
  },
  // Delete users from role
  DELETE_USERS_FROM_ROLE_LOADING(state: RoleState) {
    state.apiState.deleteUsersFromRole = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_USERS_FROM_ROLE_SUCCESS(state: RoleState) {
    state.apiState.deleteUsersFromRole = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_USERS_FROM_ROLE_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.deleteUsersFromRole = {
      ...defaultApiState,
      error
    };
  },
  // Delete groups from role
  DELETE_GROUPS_FROM_ROLE_LOADING(state: RoleState) {
    state.apiState.deleteGroupsFromRole = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_GROUPS_FROM_ROLE_SUCCESS(state: RoleState) {
    state.apiState.deleteGroupsFromRole = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_GROUPS_FROM_ROLE_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.deleteGroupsFromRole = {
      ...defaultApiState,
      error
    };
  },
  // Delete invitations from role
  DELETE_INVITATIONS_FROM_ROLE_LOADING(state: RoleState) {
    state.apiState.deleteInvitationsFromRole = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_INVITATIONS_FROM_ROLE_SUCCESS(state: RoleState) {
    state.apiState.deleteInvitationsFromRole = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_INVITATIONS_FROM_ROLE_ERROR(state: RoleState, error: AxiosError) {
    state.apiState.deleteInvitationsFromRole = {
      ...defaultApiState,
      error
    };
  }
};
