import { CreateOrderPayload } from '../store/modules/order';
import { axios } from './axios';

export const orderApi = {
  getMyOrders() {
    return axios.get(`orders`);
  },
  createOrder(payload: CreateOrderPayload) {
    return axios.post(`orders`, payload);
  },
  getOrder(id: number) {
    return axios.get(`orders/${id}`);
  },
  updateOrder(id: number, resultKey: string) {
    return axios.put(`orders/${id}?resultKey=${resultKey}`, {
      resultKey
    });
  },
  retryPayment(paymentId: number) {
    return axios.post(`payments/${paymentId}/retry`);
  }
};
