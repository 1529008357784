import { axios } from '@/api/axios';
import { ConvertWKUserPayload } from '../store/modules/sumari/sumari.types';

export const sumariApi = {
  getWkSubscription() {
    return axios.get(`sumari/wk-subscription`);
  },
  convertWkUserToInternal(params: ConvertWKUserPayload) {
    return axios.patch(`sumari/convert`, {
      ...params
    });
  }
};
