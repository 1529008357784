import { staticFileApi } from '@/api/static-file.api';
import { Store } from 'vuex';
import { GroupLevelAttributeUploadableSignedUrlRequestPayload } from '../../modules/admin/types/group-level-attribute.types';
import {
  GetSignedUrlForUploadRequestPayload,
  GetSignedUrlForUploadResponsePayload,
  StaticApiState,
} from './types/static-file.types';

export const staticFileActions = {
  async getSignedUrlForUploads(
    store: Store<StaticApiState>,
    payload: GetSignedUrlForUploadRequestPayload,
  ) {
    try {
      store.commit('GET_SIGNED_URL_FOR_UPLOAD_LOADING');
      // prettier-ignore
      const { data }: {
        data: GetSignedUrlForUploadResponsePayload;
      } = await staticFileApi.getSignedUrlForUpload(payload);

      store.commit('GET_SIGNED_URL_FOR_UPLOAD_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_SIGNED_URL_FOR_UPLOAD_ERROR', error);
    }
  },

  async getSignedUrlForGroupLevelAttributeUploadable(
    store: Store<StaticApiState>,
    params: GroupLevelAttributeUploadableSignedUrlRequestPayload[],
  ) {
    try {
      store.commit('GET_GROUP_LEVEL_ATTRIBUTE_UPLOADABLES_SIGNED_URL_LOADING');

      const {
        data,
      } = await staticFileApi.getSignedUrlForGroupLevelAttributeUploadable(
        params,
      );

      store.commit(
        'GET_GROUP_LEVEL_ATTRIBUTE_UPLOADABLES_SIGNED_URL_SUCCESS',
        data,
      );
    } catch (error) {
      store.commit(
        'GET_GROUP_LEVEL_ATTRIBUTE_UPLOADABLES_SIGNED_URL_ERROR',
        error,
      );
    }
  },
};
