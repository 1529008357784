import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

import {
  journalState,
  journalGetters,
  journalActions,
  journalMutations,
} from './';

export default {
  namespaced: true,
  state: journalState,
  getters: journalGetters,
  actions: journalActions,
  mutations: journalMutations,
} as ModuleTree<RootState>;
