import _axios from 'axios';
import {
  nprogressErrorInterceptor,
  nprogressRequestInterceptor,
  nprogressResponseInterceptor
} from '../jbi-shared/axios-interceptors/axios-loading.interceptor';

export const axios = _axios;

// interceptor to display nprogress
axios.interceptors.request.use(
  nprogressRequestInterceptor,
  nprogressErrorInterceptor
);
axios.interceptors.response.use(
  nprogressResponseInterceptor,
  nprogressErrorInterceptor
);
