import { applicationApi } from '@/api/application.api';
import {
  ApplicationState,
  DeleteProductValues
} from '@/store/modules/application/types/application.types';
import { Store } from 'vuex';
import {
  DisableProductValues,
  GetAdministrativeProductsQuery,
  GetApplicationsPayload,
  GetProductsPayload,
  ProductFormValues
} from './types/application.types';

export const applicationActions = {
  async getApplications(
    store: Store<ApplicationState>,
    payload: GetApplicationsPayload
  ) {
    try {
      store.commit('GET_APPLICATIONS_LOADING');
      const { data } = await applicationApi.getApplications(payload);

      store.commit('GET_APPLICATIONS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_APPLICATIONS_ERROR', error);
    }
  },
  async getProduct(
    store: Store<ApplicationState>,
    query: { productId: number }
  ) {
    try {
      store.commit('GET_PRODUCT_LOADING');

      const { data } = await applicationApi.getOneProduct(query);
      store.commit('GET_PRODUCT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PRODUCT_ERROR', error);
    }
  },
  async getProducts(
    store: Store<ApplicationState>,
    payload: GetProductsPayload
  ) {
    try {
      store.commit('GET_PRODUCTS_LOADING');

      const { data } = await applicationApi.getProducts(payload);
      store.commit('GET_PRODUCTS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PRODUCTS_ERROR', error);
    }
  },
  async getAdministrationProducts(
    store: Store<ApplicationState>,
    query: GetAdministrativeProductsQuery
  ) {
    try {
      store.commit('GET_ADMINISTRATION_PRODUCTS_LOADING');

      const { data } = await applicationApi.getAdministrationProducts(query);

      store.commit('GET_ADMINISTRATION_PRODUCTS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ADMINISTRATION_PRODUCTS_ERROR', error);
    }
  },
  async createProduct(
    store: Store<ApplicationState>,
    payload: ProductFormValues
  ) {
    try {
      store.commit('CREATE_PRODUCT_LOADING');

      await applicationApi.createProduct(payload);

      store.commit('CREATE_PRODUCT_SUCCESS');
    } catch (error) {
      store.commit('CREATE_PRODUCT_ERROR', error);
    }
  },
  async updateProduct(
    store: Store<ApplicationState>,
    payload: ProductFormValues
  ) {
    try {
      store.commit('UPDATE_PRODUCT_LOADING');

      await applicationApi.updateProduct(payload);

      store.commit('UPDATE_PRODUCT_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_PRODUCT_ERROR', error);
    }
  },
  async getLicenseModels(store: Store<ApplicationState>) {
    try {
      store.commit('GET_LICENSE_MODELS_LOADING');

      const { data } = await applicationApi.getLicenseModels();

      store.commit('GET_LICENSE_MODELS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_LICENSE_MODELS_ERROR', error);
    }
  },
  async disableProduct(
    store: Store<ApplicationState>,
    payload: DisableProductValues
  ) {
    try {
      store.commit('DISABLE_PRODUCT_LOADING');

      await applicationApi.disableProduct(payload);

      store.commit('DISABLE_PRODUCT_SUCCESS');
    } catch (error) {
      store.commit('DISABLE_PRODUCT_ERROR', error);
    }
  },
  async deleteProduct(
    store: Store<ApplicationState>,
    payload: DeleteProductValues
  ) {
    try {
      store.commit('DELETE_PRODUCT_LOADING');

      await applicationApi.deleteProduct(payload);

      store.commit('DELETE_PRODUCT_SUCCESS');
    } catch (error) {
      store.commit('DELETE_PRODUCT_ERROR', error);
    }
  },

  setCurrentProduct(store: Store<ApplicationState>, payload: string) {
    store.commit('SET_CURRENT_PRODUCT', payload);
  },

  setCurrentProductStatus(store: Store<ApplicationState>, payload: string) {
    store.commit('SET_CURRENT_PRODUCT_STATUS', payload);
  }
};
